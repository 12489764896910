import React, { useEffect, useState, useCallback } from 'react';
import { Flex, Button, useToast } from '@chakra-ui/react';
import { MotionFlex } from '../config/config';
import { useSignupFormContext } from '../providers/SignupFormProvider';
import { useUserContext } from '../providers/UserProvider';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import ProfileSetupStart from '../components/ProfileSetup/ProfileSetupStart';
import ProfileSetupTypeOfContent from '../components/ProfileSetup/ProfileSetupTypeOfContent';
import ProfileSetupSkillLevel from '../components/ProfileSetup/ProfileSetupSkillLevel';
import ProfileSetupOptins from '../components/ProfileSetup/ProfileSetupOptins';
import { updateUser } from '../services/user.service';
import useStripeSuccess from '../hooks/useStripeSuccess';

const SubscriptionConfirmation = () => {
  const toast = useToast();
  const { contentTypes, skillLevel, marketingOptIn, enhanceApp } = useSignupFormContext();
  const { user, setUser, token } = useUserContext();
  const { handleStripeSuccess } = useStripeSuccess();

  const [currentStep, setCurrentStep] = useState(1);
  const isStripeEnabled = process.env.REACT_APP_STRIPE_ENABLED === 'true';

  const isNextDisabled = useCallback(() => {
    switch (currentStep) {
      case 1:
        return false;
      case 2:
        return !contentTypes.some(contentType => contentType.isChecked);
      case 3:
        return skillLevel === '';
      default:
        return true;
    }
  }, [currentStep, contentTypes, skillLevel]);

  useEffect(() => {
    const handleSuccess = async () => {
      const params = new URLSearchParams(window.location.search);
      const sessionId = params.get('session_id');

      if (user && sessionId) {
        console.log('Subscription Success', sessionId);

        const success = await handleStripeSuccess(sessionId);

        if (success) {
          // Update the user state with the new subscription status
          const updatedUser = { ...user, subscriptionStatus: 'active' };
          setUser(updatedUser);

          // Update backend
          await updateUser(updatedUser._id, updatedUser, token);

          // Clear query params
          window.history.replaceState(null, '', window.location.pathname);
        }
      }
    };

    if (isStripeEnabled) {
      handleSuccess();
    }
  }, [user, token, isStripeEnabled, handleStripeSuccess, setUser]);

  const handleNextStep = () => setCurrentStep(prevStep => prevStep + 1);

  const handleNextStepClick = event => {
    event.preventDefault();
    event.stopPropagation();
    handleNextStep();
  };

  const handlePrevStep = () => setCurrentStep(prevStep => Math.max(prevStep - 1, 1));

  const handleSubmit = async event => {
    event.preventDefault();
    console.log('Submitting form data...');

    try {
      // Update user profile completion status
      const updatedUser = {
        ...user,
        contentTypes,
        skillLevel,
        marketingOptIn,
        enhanceApp,
        subscriptionStatus: 'active', // Directly mark subscription as active for beta users
      };

      await updateUser(updatedUser._id, updatedUser, token);
      setUser(updatedUser);

      toast({
        title: 'Profile updated',
        description: 'Your profile has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error updating your profile.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderStepContent = step => {
    switch (step) {
      case 1:
        return <ProfileSetupStart />;
      case 2:
        return <ProfileSetupTypeOfContent step={step} />;
      case 3:
        return <ProfileSetupSkillLevel step={step} />;
      case 4:
        return <ProfileSetupOptins step={step} />;
      default:
        return null;
    }
  };

  const calculateProgress = useCallback(() => {
    if (currentStep === 1) return 0;
    const adjustedStep = currentStep - 1;
    const totalSteps = 3;
    return (adjustedStep / totalSteps) * 100;
  }, [currentStep]);

  const progressValue = calculateProgress();

  useEffect(() => {
    console.log('Current Step:', Math.floor(calculateProgress()), '%');
  }, [calculateProgress]);

  return (
    <Flex minHeight="100vh" align="center" justify="center" bg="gunmetal.550">
      <MotionFlex
        flexDirection={'column'}
        p={8}
        borderRadius="md"
        boxShadow="sm"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <form onSubmit={handleSubmit}>
          {renderStepContent(currentStep)}
          <Flex mt={16} justify="center" gap={4}>
            {currentStep > 1 && (
              <Button
                colorScheme="teal"
                variant="ghost"
                px={16}
                py={8}
                fontSize={'xl'}
                rounded={'xl'}
                color={'white'}
                onClick={handlePrevStep}
              >
                Back
              </Button>
            )}
            {currentStep < 4 ? (
              <Button
                colorScheme="teal"
                px={16}
                py={8}
                onClick={handleNextStepClick}
                isDisabled={isNextDisabled()}
                fontSize={'xl'}
                textAlign={'center'}
                rounded={'xl'}
                type="button"
              >
                Next
              </Button>
            ) : (
              <Button
                colorScheme="teal"
                fontSize={'xl'}
                px={16}
                py={8}
                textAlign={'center'}
                rounded={'xl'}
                type="submit"
              >
                Finish
              </Button>
            )}
          </Flex>
        </form>

        <ProgressBar
          currentStep={currentStep}
          progressValue={progressValue}
          position="fixed"
        />
      </MotionFlex>
    </Flex>
  );
};

export default SubscriptionConfirmation;
