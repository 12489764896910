import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Text,
  VStack,
  Editable,
  EditablePreview,
  Tooltip,
  EditableTextarea,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  Box,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  useToast,
  Icon,
  Divider,
} from '@chakra-ui/react';
import FlyoutMenu from '../FlyoutMenu/FlyoutMenu';
import MusicSelection from '../MusicSelection/MusicSelection';
import EditableControls from '../EditableControls/EditableControls';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import { useUserContext } from '../../providers/UserProvider';
import useManageMusic from '../../hooks/useManageMusic';
import useAudioPlayer from '../../hooks/useAudioPlayer';
import useGetScriptById from '../../hooks/useGetScriptById';
import useSaveScript from '../../hooks/useSaveScript';
import { useNavigate } from 'react-router-dom';
import useGenerateVoiceover from '../../hooks/useGenerateVoiceover';
import useManageVoiceover from '../../hooks/useManageVoiceover';
import { FPS, SPEED } from '../../config/config';
import { ChevronLeftIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { FaFileExport, FaArrowsRotate } from 'react-icons/fa6';
import {
  durationToSMPTE,
  totalDuration,
  parseTargetDuration,
  smpteToSeconds,
  ensureProperSpacing,
} from '../../utils/app.utils';

const ScriptEditor = () => {
  const { user } = useUserContext();

  const bgColor = useColorModeValue('gray.50', 'gunmetal.700');
  const borderColor = useColorModeValue('gray.200', 'gunmetal.550');
  const headerBgColor = useColorModeValue('white', 'gunmetal.600');
  const cardBgColor = useColorModeValue('white', 'gunmetal.600');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const iconBgColor = useColorModeValue('gray.200', 'gunmetal.500');
  const iconHoverBgColor = useColorModeValue('gray.300', 'gunmetal.400');
  const hoverBgColor = useColorModeValue('gray.100', 'gunmetal.500');
  const buttonBgColor = useColorModeValue('beige.300', 'beige.300');
  const buttonHoverBgColor = useColorModeValue('beige.400', 'beige.400');
  const errorColor = useColorModeValue('red.500', 'red.300');

  const toast = useToast();
  const { scriptId } = useParams();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isFlyoutOpen,
    onOpen: onFlyoutOpen,
    onClose: onFlyoutClose,
  } = useDisclosure();

  const { script, scriptLoading, error } = useGetScriptById(scriptId);
  const { updateScriptData, saveLoading } = useSaveScript();
  const { generateVoiceover, voiceoverLoading } = useGenerateVoiceover();
  const { addAudioToProject, removeAudioFromProject } = useManageVoiceover();
  const { addSongToProject, removeSongFromProject } = useManageMusic();

  const [shots, setShots] = useState([]);
  const [voiceoverUrl, setvoiceoverUrl] = useState(
    script?.voiceover?.audioPath || null
  );
  const [musicUrl, setMusicUrl] = useState(script?.music?.url || null);
  const [timeOverLimit, setTimeOverLimit] = useState(false);

  const {
    isPlaying,
    currentTime,
    duration,
    volume,
    frequencyData,
    playPause,
    changeVolume,
    seek,
    initializeAudioContext,
  } = useAudioPlayer(voiceoverUrl);

  const {
    isPlaying: isPlayingMusic,
    currentTime: currentTimeMusic,
    duration: durationMusic,
    volume: volumeMusic,
    frequencyData: frequencyDataMusic,
    playPause: playPauseMusic,
    changeVolume: changeVolumeMusic,
    seek: seekMusic,
  } = useAudioPlayer(musicUrl);

  useEffect(() => {
    console.log('Script:', script);
    console.log('Shots:', shots);
    console.log('Voiceover URL:', voiceoverUrl);
    console.log('Music URL:', musicUrl);
  }, [user, script, voiceoverUrl, musicUrl, shots]);

  // Detect if the duration is over the limit
  useEffect(() => {
    if (script) {
      const scriptDuration = totalDuration(shots, FPS, SPEED);
      const targetDurationSeconds = parseTargetDuration(script.duration);
      const currentDurationSeconds = smpteToSeconds(scriptDuration, FPS);

      if (currentDurationSeconds > targetDurationSeconds) {
        console.log('Time Over Limit');
        setTimeOverLimit(true);
      } else {
        setTimeOverLimit(false);
      }
    }
  }, [voiceoverUrl, script, shots]);

  useEffect(() => {
    if (script) {
      setShots(script?.script);
    }
    if (script?.voiceover) {
      setvoiceoverUrl(script.voiceover.audioPath);
    }
    if (script?.music) {
      setMusicUrl(script.music.url);
    }
  }, [script]);

  const handleDialogueChange = (value, shotId) => {
    const updatedShots = shots.map(shot => {
      if (shot._id === shotId) {
        const updatedAudio = shot.audio.map(item => {
          if (item.type === 'DIALOGUE') {
            return { ...item, content: value };
          }
          return item;
        });
        return { ...shot, audio: updatedAudio };
      }
      return shot;
    });
    setShots(updatedShots);
  };


  const handleBack = () => {
    navigate('/scripts');
  };

  const handleSave = async () => {
    try {
      const updatedScript = {
        _id: scriptId,
        script: shots,
      };

      const savedScript = await updateScriptData(scriptId, updatedScript);
      console.log('Updated Shots:', savedScript);
    } catch (error) {
      console.error('Error saving shots:', error);
    }
  };

  const handleGenerateVoiceover = async () => {
    try {
      const newVoiceover = await generateVoiceover(script);

      if (newVoiceover) {
        setvoiceoverUrl(newVoiceover);

        const audioData = {
          title: script.title,
          audioPath: newVoiceover,
        };

        // Add the audio to the project
        const addedVoiceover = await addAudioToProject({
          projectId: script.project,
          scriptId,
          audioData,
        });

        console.log('Added Voiceover:', addedVoiceover);
      }
    } catch (error) {
      console.error('Error generating voiceover:', error);
    }
  };

  const handleRemoveVoiceover = async () => {
    try {
      setvoiceoverUrl(null);

      await removeAudioFromProject({
        projectId: script.project,
        scriptId,
        audioId: script.voiceover._id,
      });

      toast({
        title: 'Voiceover removed',
        description: 'The voiceover has been removed successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error removing voiceover:', error);
      toast({
        title: 'Error',
        description: 'Failed to remove voiceover. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRegenerateVoiceover = async () => {
    try {
        // Remove the current voiceover from the DB
        await removeAudioFromProject({
        projectId: script.project,
        scriptId,
        audioId: script.voiceover._id,
      });

        // Generate a new voiceover
        const newVoiceover = await generateVoiceover(script);

        // Update the voiceover URL
        setvoiceoverUrl(newVoiceover);

        toast({
          title: 'Voiceover regenerated',
          description: 'The voiceover has been regenerated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
    } catch (error) {
      console.error('Error regenerating voiceover:', error);
      toast({
        title: 'Error',
        description: 'Failed to regenerate voiceover. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleMusicModalOpen = () => {
    console.log('Select Music');
    onOpen();
  };

  const handleMusicSelect = async selectedMusic => {
    try {
      const musicData = {
        title: selectedMusic.title,
        url: selectedMusic.url,
      };

      // const addedMusic = await addSongToProject({ projectId: script.project, musicId: selectedMusic._id, musicData });
      // console.log('Added Music:', addedMusic);

      setMusicUrl(selectedMusic.url);

      // onClose();
    } catch (error) {
      console.error('Error adding music:', error);
      toast({
        title: 'Error',
        description: 'Failed to add music. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveMusic = async () => {
    try {
      await removeSongFromProject({
        projectId: script.project,
        scriptId,
        musicId: script.music._id,
      });
      setMusicUrl(null);
    } catch (error) {
      console.error('Error removing music:', error);
      toast({
        title: 'Error',
        description: 'Failed to remove music. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleAddShot = () => {
    const newShot = {
      _id: Date.now().toString(), // Temporary unique ID
      audio: [
        {
          type: 'DIALOGUE',
          content: '',
          phoneme: { alphabet: null, ph: null },
        },
      ],
      visual: [],
    };

    setShots(prevShots => [...prevShots, newShot]);
  };

  const handleRemoveShot = shotId => {
    setShots(prevShots => prevShots.filter(shot => shot._id !== shotId));
  };

  const handleEditDuration = () => {
    console.log('Edit Duration');
    
  };

  const handleExport = () => {
    console.log('Export');
  };

  const getLoadingText = () => {
    if (saveLoading) return "Saving...";
    if (voiceoverLoading) return "Processing Voiceover...";
    // if (musicLoading) return "Processing Music...";
    return "Loading...";
  };

  if (scriptLoading) {
    return (
      <Flex justify="center" align="center" h="100vh" bg={bgColor}>
        <Spinner color={textColor} />
        <Text ml={3} color={subTextColor}>
          Loading...
        </Text>
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" h="100vh" bg={bgColor}>
        <Text color={errorColor}>Error: {error.message}</Text>
      </Flex>
    );
  }

  return (
    <>
      <Flex
        direction="column"
        p={4}
        borderRadius={'lg'}
        bg={bgColor}
        boxShadow="xl"
        border={'1px solid'}
        borderColor={borderColor}
        mr={20}
      >
        {/* Header */}
        <HStack
          px={4}
          py={4}
          id="header-editor"
          w="full"
          spacing={4}
          border="1px solid"
          borderColor={borderColor}
          shadow={'md'}
          alignItems="center"
          bg={headerBgColor}
          borderRadius={'md'}
          mb={8}
        >
          {/* Back Button */}
          <IconButton
            aria-label="Back"
            icon={<ChevronLeftIcon />}
            onClick={handleBack}
            bg={iconBgColor}
            _hover={{ bg: iconHoverBgColor }}
          />

          {/* Script Name */}
          <Heading
            fontSize={['xs', 'sm', '2xl', '2xl']}
            flex={1}
            textAlign="left"
            fontWeight={'700'}
            color={textColor}
          >
            {`"${script?.title}"` || 'Loading...'}
          </Heading>

          <VStack align={'stretch'} justify={'stretch'} mr={4}>

          <Flex align={'center'} justify={'stretch'} gap={2}>
            <Text color={subTextColor} textAlign={'left'}>
              Target Duration: {script?.duration || 'Loading...'}
            </Text>
            <IconButton
              icon={<EditIcon />}
              aria-label="Edit"
              size="xs"
              borderRadius={'md'}
              onClick={handleEditDuration}
              bg={iconBgColor}
              _hover={{ bg: iconHoverBgColor }}
            />
          </Flex>
          

          {timeOverLimit ? (
            <Tooltip
              label="The current duration exceeds the target duration"
              aria-label="The current duration exceeds the target duration"
            >
              <Text color={errorColor}>
                Current Duration:{' '}
                <span style={{ color: errorColor }}>
                  {totalDuration(shots, FPS, SPEED)}
                </span>
              </Text>
            </Tooltip>
          ) : (
            <Text color={subTextColor}>
              Est. Current Duration: {totalDuration(shots, FPS, SPEED)}
            </Text>
          )}

          </VStack>

          {/* Save Button */}
          <Button
            onClick={handleSave}
            loadingText="Saving..."
            isLoading={saveLoading || voiceoverLoading}
            bg={buttonBgColor}
            _hover={{ bg: buttonHoverBgColor }}
          >
            Save
          </Button>
        </HStack>

        {/* Content */}
        <HStack align={'stretch'} justify={'stretch'} gap={8}>
          <VStack
            flex="2"
            spacing={4}
            mt={4}
            align="stretch"
            justify={'stretch'}
          >
            {shots.map((shot, index) => {
              const dialogue = shot.audio
                .filter(item => item.type === 'DIALOGUE')
                .map(item => item.content)
                .join(' ');
              const words = dialogue.split(' ').length;
              const estimatedDuration = durationToSMPTE(words, FPS, SPEED);

              return (
                <Flex
                  key={shot._id}
                  p={6}
                  borderRadius="md"
                  boxShadow="lg"
                  bg={cardBgColor}
                  border="1px solid"
                  borderColor={borderColor}
                  shadow={'md'}
                  mb={6}
                  position="relative"
                  direction="column"
                >
                  <Editable
                    defaultValue={ensureProperSpacing(dialogue)}
                    onSubmit={value => handleDialogueChange(value, shot._id)}
                    placeholder="Add your Dialogue"
                    fontSize={'lg'}
                    fontWeight={'normal'}
                    w="full"
                    color={textColor}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Tooltip label="Click to edit" aria-label="Click to edit">
                      <EditablePreview
                        py={2}
                        px={4}
                        _hover={{ bg: hoverBgColor }}
                        mr={4}
                      />
                    </Tooltip>

                    <EditableTextarea py={2} px={4} flex={1} mr={2} />
                    <EditableControls
                      onDelete={() => handleRemoveShot(shot._id)}
                    />
                  </Editable>

                  <Text
                    fontSize="sm"
                    color={subTextColor}
                    position="absolute"
                    top="-20px"
                    right="10px"
                  >
                    Estimated Duration: {estimatedDuration}
                  </Text>
                </Flex>
              );
            })}

            <Flex
              bg={'transparent'}
              flexDirection={'column'}
              p={4}
              align={'center'}
              justify={'center'}
              borderRadius="md"
              borderColor={timeOverLimit ? 'gray.400' : borderColor}
              borderWidth="1px"
              borderStyle={'dashed'}
              transition={'all 0.3s'}
              onClick={handleAddShot}
              _hover={{
                bg: hoverBgColor,
                cursor: 'pointer',
                borderColor: borderColor,
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
              opacity={1}
            >
              <Text>Add Script Block</Text>
            </Flex>
          </VStack>

          <VStack
            mt={0}
            flex={'1'}
            spacing={4}
            align={'stretch'}
            justify={'stretch'}
          >
            {voiceoverUrl ? (
              <Box position="relative">
                <Text
                  textAlign={'center'}
                  fontSize="sm"
                  fontWeight="bold"
                  mb={2}
                >
                  Voiceover
                </Text>
                <AudioPlayer
                  isPlaying={isPlaying}
                  currentTime={currentTime}
                  duration={duration}
                  volume={volume}
                  frequencyData={frequencyData}
                  onPlayPause={playPause}
                  onVolumeChange={changeVolume}
                  onSeek={seek}
                />
                <IconButton
                  icon={<CloseIcon />}
                  aria-label="Remove voiceover"
                  position="absolute"
                  top="2"
                  right="2"
                  size="sm"
                  onClick={handleRemoveVoiceover}
                  bg={'beige.300'}
                  _hover={{ bg: 'red.400' }}
                />
              </Box>
            ) : (
              <Tooltip
                label="Duration exceeds the target limit. Please adjust your script."
                isDisabled={!timeOverLimit}
                shouldWrapChildren
              >
                <Flex
                  bg={'transparent'}
                  flexDirection={'column'}
                  p={4}
                  align={'center'}
                  justify={'center'}
                  borderRadius="md"
                  borderColor={timeOverLimit ? 'gray.400' : borderColor}
                  borderWidth="1px"
                  borderStyle={'dashed'}
                  transition={'all 0.3s'}
                  onClick={!timeOverLimit ? handleGenerateVoiceover : undefined}
                  _hover={{
                    bg: !timeOverLimit ? hoverBgColor : 'transparent',
                    cursor: timeOverLimit ? 'not-allowed' : 'pointer',
                    borderColor: timeOverLimit ? 'gray.400' : borderColor,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                  opacity={timeOverLimit ? 0.6 : 1}
                >
                  {voiceoverLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <>
                      <Heading size="md" color={textColor}>
                        Generate Voiceover
                      </Heading>
                      <Text textAlign="center" color={subTextColor}>
                        Costs 10 credits
                      </Text>
                    </>
                  )}
                </Flex>
              </Tooltip>
            )}

            {voiceoverUrl && (
              <Flex
                position="relative"
                flexDirection={'column'}
                justify={'center'}
                align={'stretch'}
                mb={4}
              >
                <Button
                  onClick={handleRegenerateVoiceover}
                  isLoading={voiceoverLoading || saveLoading}
                  loadingText="Regenerating..."
                  bg={'beige.300'}
                  _hover={{ bg: 'beige.400' }}
                  leftIcon={<Icon as={FaArrowsRotate} />}
                  mb={2}
                >
                  Regenerate Voiceover
                </Button>
                <Text fontSize="sm" color={subTextColor} textAlign="center">
                  10 Credits
                </Text>
              </Flex>
            )}

            <Divider />

            {musicUrl ? (
              <Box position="relative">
                <Text
                  textAlign={'center'}
                  fontSize="sm"
                  fontWeight="bold"
                  mb={2}
                >
                  Music
                </Text>
                <AudioPlayer
                  isPlaying={isPlayingMusic}
                  currentTime={currentTimeMusic}
                  duration={durationMusic}
                  volume={volumeMusic}
                  frequencyData={frequencyDataMusic}
                  onPlayPause={playPauseMusic}
                  onVolumeChange={changeVolumeMusic}
                  onSeek={seekMusic}
                />
                <IconButton
                  icon={<CloseIcon />}
                  aria-label="Remove music"
                  position="absolute"
                  top="2"
                  right="2"
                  size="sm"
                  onClick={handleRemoveMusic}
                  bg={'beige.300'}
                  _hover={{ bg: 'red.400' }}
                />
              </Box>
            ) : (
              <Tooltip
                label="Duration exceeds the target limit. Please adjust your script."
                isDisabled={!timeOverLimit}
                shouldWrapChildren
              >
                <Flex
                  bg={'transparent'}
                  flexDirection={'column'}
                  p={4}
                  align={'center'}
                  justify={'center'}
                  borderRadius="md"
                  borderColor={timeOverLimit ? 'gray.400' : borderColor}
                  borderWidth="1px"
                  borderStyle={'dashed'}
                  transition={'all 0.3s'}
                  onClick={!timeOverLimit ? handleMusicModalOpen : undefined}
                  _hover={{
                    bg: !timeOverLimit ? hoverBgColor : 'transparent',
                    cursor: timeOverLimit ? 'not-allowed' : 'pointer',
                    borderColor: timeOverLimit ? 'gray.400' : borderColor,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                  opacity={timeOverLimit ? 0.6 : 1}
                >
                  <Heading size="md" color={textColor}>
                    Music Selection
                  </Heading>
                  <Text textAlign="center" color={subTextColor}>
                    Costs 10 credits
                  </Text>
                </Flex>
              </Tooltip>
            )}

            <Button
              bg={buttonBgColor}
              _hover={{ bg: buttonHoverBgColor }}
              onClick={handleExport}
              leftIcon={<Icon as={FaFileExport} />}
              isDisabled={!musicUrl || !voiceoverUrl}
              isLoading={saveLoading || voiceoverLoading}
              loadingText={getLoadingText()}
              mt={8}
            >
              Export
            </Button>
          </VStack>
        </HStack>

        {/* Modal for Music Selection */}
        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent bg={bgColor}>
            <ModalCloseButton />
            <ModalBody>
              <MusicSelection
                onMusicSelect={handleMusicSelect}
                onMusicRemove={handleRemoveMusic}
                selectedMusicUrl={musicUrl}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Box
        position="fixed"
        bottom="50%"
        right="-50px"
        transform={'rotate(-90deg)'}
      >
        <Button
          bg={'beige.300'}
          _hover={{ bg: 'beige.400' }}
          onClick={onFlyoutOpen}
        >
          Project Settings
        </Button>
      </Box>

      <FlyoutMenu isOpen={isFlyoutOpen} onClose={onFlyoutClose} />
    </>
  );
};

export default ScriptEditor;
