import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Text,
  VStack,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
} from '@chakra-ui/react';
import { IoEllipsisHorizontal, IoDownload } from 'react-icons/io5';
import NewVoice from '../assets/NewVoice.svg';
import useGetProjectById from '../hooks/useGetProjectById';
import useDownloadScript from '../hooks/useDownloadScript';

import { countWordsInArray, estimateVideoDuration } from '../utils/app.utils';

const Project = () => {
  const navigate = useNavigate();
  let { projectId } = useParams();
  const { project, isLoading, error } = useGetProjectById(projectId);
  const { newDownloadData } = useDownloadScript();

  const [selectedProject, setSelectedProject] = useState(null);

  const menuItems = ['Duplicate', 'Delete'];

  const countWordsInAudio = scriptItems => {
    return scriptItems.reduce(
      (acc, item) => acc + item.audio.split(' ').length,
      0
    );
  };

  const estimateVideoDuration = wordCount => {
    const durationInMinutes = wordCount / 150;
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.floor(durationInMinutes % 60);
    const seconds = Math.round(((durationInMinutes % 60) - minutes) * 60);

    // Format the duration as "HH:MM:SS"
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    if (project) {
      const updatedScripts = project?.scripts?.map(script => {
        const wordCount = countWordsInAudio(script.script);
        const duration = estimateVideoDuration(wordCount);
        return { ...script, wordCount, duration };
      });

      setSelectedProject({ ...project, scripts: updatedScripts });
    }
  }, [project]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!selectedProject) return <div>No project found</div>;

  const handleDownload = async (scriptId, type, scriptTitle) => {
    try {
      newDownloadData(scriptId, type, scriptTitle);
    } catch (error) {
      console.error('Error downloading script:', error);
    }
  };

  return (
    <Flex
      p={10}
      direction={'column'}
      align={'center'}
      justify={'center'}
      flex={1}
    >
      <Heading as={'h2'} fontSize={'3xl'} mb={3} textAlign={'center'}>
        Project
      </Heading>

      <Heading as={'h1'} fontSize={'5xl'} mb={10} textAlign={'center'}>
        {project.title}
      </Heading>

      {selectedProject?.scripts?.map(script => (
        <Grid
          templateColumns="1fr 1fr"
          gap={4}
          w={'100%'}
          mb={5}
          key={script._id}
        >
          <VStack spacing={0} justify={'start'} align={'stretch'}>
            <Heading as={'h3'} fontSize={'xl'} textAlign={'center'} mb={8}>
              Script
            </Heading>
            <Flex
              background={'gradGunmetal'}
              p={10}
              borderRadius={'xl'}
              flexDirection={'column'}
              height={'100%'}
              justify={'center'}
              align={'center'}
            >
              <Heading as={'h3'} fontSize={'3xl'} textAlign={'center'} mb={4}>
                {script.title}
              </Heading>
              <Text fontSize={'md'} color={'beige.200'} textAlign={'center'}>
                Word Count: {script.wordCount} Words
              </Text>
              <Text
                fontSize={'md'}
                color={'beige.200'}
                textAlign={'center'}
                mb={8}
              >
                Estimated Duration: {script.duration}
              </Text>
              <Button
                bg={'gunmetal.400'}
                _hover={{ bg: 'gunmetal.300' }}
                w={'100%'}
                mb={4}
                onClick={() => navigate(`/script/${script._id}/edit`)}
              >
                Edit Script
              </Button>

              <Flex align="center" justify="center">
                <Popover>
                  <PopoverTrigger>
                    <Button
                      bg={'gunmetal.400'}
                      _hover={{ bg: 'gunmetal.300' }}
                      rightIcon={<IoDownload />}
                      mb={4}
                    >
                      Download
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    bg={'gunmetal.400'}
                    borderColor={'gunmetal.400'}
                    color={'beige.200'}
                    _focus={{ boxShadow: 'none', outline: 'none' }}
                  >
                    <PopoverArrow
                      bg={'gunmetal.400'}
                      borderColor={'transparent'}
                    />
                    <PopoverCloseButton />
                    <PopoverHeader color={'white'} textAlign={'center'}>
                      Download As
                    </PopoverHeader>
                    <PopoverBody>
                      <Flex
                        align="center"
                        justify="center"
                        wrap="wrap"
                        my={4}
                        gap={4}
                      >
                        <Button
                          onClick={() =>
                            handleDownload(script._id, 'txt', script.title)
                          }
                          _hover={{ bg: 'gunmetal.300' }}
                        >
                          TXT
                        </Button>
                        <Button
                          onClick={() =>
                            handleDownload(script._id, 'docx', script.title)
                          }
                          _hover={{ bg: 'gunmetal.300' }}
                        >
                          DOCX
                        </Button>
                        <Button
                          onClick={() =>
                            handleDownload(script._id, 'pdf', script.title)
                          }
                          _hover={{ bg: 'gunmetal.300' }}
                        >
                          PDF
                        </Button>
                      </Flex>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>

              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label={'Script Settings'}
                  icon={<Icon as={IoEllipsisHorizontal} />}
                  size="lg"
                  variant="unstyled"
                  rounded={'md'}
                  bg={'gunmetal.400'}
                  _hover={{ bg: 'gunmetal.300' }}
                  transform={{ base: 'rotate(90deg)', md: 'rotate(0)' }}
                />
                <MenuList bg={'gunmetal.400'} zIndex={9999} color={'#E5D3B8'}>
                  {menuItems.map((item, index) => (
                    <MenuItem
                      key={index}
                      bg={'gunmetal.400'}
                      _hover={{ bg: 'gunmetal.500' }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Flex>
          </VStack>

          <VStack spacing={0} justify={'start'} align={'stretch'}>
            <Heading as={'h3'} fontSize={'xl'} textAlign={'center'} mb={8}>
              Voiceover
            </Heading>
            <Flex
              background={'gradBlack'}
              border={'2px dashed'}
              p={10}
              borderRadius={'xl'}
              flexDirection={'column'}
              height={'100%'}
              justify={'center'}
              align={'center'}
              transition={'all .25s ease-in'}
              // _hover={
              //   {
              //     cursor: 'pointer',
              //     background: 'gradBlackHover',
              //     borderColor: 'beige.200',
              //   }
              // }
            >
              <Image src={NewVoice} w={'75px'} h={'75px'} mb={2} />
              <Heading
                as={'h3'}
                fontSize={'3xl'}
                textAlign={'center'}
                mb={4}
                color={'gray.500'}
              >
                {/* Add a voice to get started */}
                Coming Soon...
              </Heading>
            </Flex>
          </VStack>
        </Grid>
      ))}
    </Flex>
  );
};

export default Project;
