import React, { useState } from 'react';
import {
  Heading,
  Text,
  Container,
  Divider,
  Stack,
  Link as ChakraLink,
  HStack,
  Button,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Skeleton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
  MenuDivider,
  VStack,
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon, EditIcon } from '@chakra-ui/icons';
import { FaFileExport, FaEllipsis } from 'react-icons/fa6';
import { FaAd } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import useGetProjects from '../hooks/useGetProjects';
import useGetAllScriptsByProject from '../hooks/useGetAllScriptsByProject';
import useDeleteProject from '../hooks/useDeleteProject';
import useCreateProject from '../hooks/useCreateProject';
import useDownloadScript from '../hooks/useDownloadScript';
import useDeleteScript from '../hooks/useDeleteScript';
import DeleteItemModal from '../components/DeleteItemModal/DeleteItemModal';
import CreateProjectModal from '../components/CreateProjectModal/CreateProjectModal';
import ExportModal from '../components/ExportModal/ExportModal';
import { AnimatePresence } from 'framer-motion';
import { MotionBox, containerVariants, itemVariants } from '../config/config';

const Projects = () => {
  const bgColor = useColorModeValue('beige.100', 'gunmetal.700');
  const borderColor = useColorModeValue('beige.100', 'gunmetal.550');
  const cardBgColor = useColorModeValue('beige.200', 'gunmetal.600');
  const scriptBgColor = useColorModeValue('beige.100', 'gunmetal.500');
  const headingColor = useColorModeValue('gray.800', 'white');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const linkHoverColor = useColorModeValue('gray.600', 'beige.200');
  const projectLinkColor = useColorModeValue('gray.600', 'white');
  const buttonBgColor = useColorModeValue('beige.300', 'beige.300');
  const buttonHoverColor = useColorModeValue('beige.400', 'beige.400');
  const buttonTextColor = useColorModeValue('gray.800', 'white');
  const iconColor = useColorModeValue('gray.600', 'white');
  const badgeBgColor = useColorModeValue('beige.300', 'gunmetal.400');
  const badgeTextColor = useColorModeValue('gray.800', 'white');

  const { projects, isLoading: projectsLoading, refetch } = useGetProjects();
  const { newDownloadData } = useDownloadScript();
  const { scripts, loading: scriptsLoading } = useGetAllScriptsByProject(projects);
  
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteItemType, setDeleteItemType] = useState('');
  const [selectedScriptId, setSelectedScriptId] = useState(null);
  const [selectedScriptTitle, setSelectedScriptTitle] = useState(null);

  const deleteProject = useDeleteProject();
  const deleteScript = useDeleteScript();
  const { createProject, isLoading: isCreatingProject } = useCreateProject();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();

  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();

  const {
    isOpen: isExportOpen,
    onOpen: onExportOpen,
    onClose: onExportClose,
  } = useDisclosure();

  const handleDeleteClick = (item, type) => {
    setItemToDelete(item);
    setDeleteItemType(type);
    onDeleteOpen();
  };

  const handleDeleteConfirm = async () => {
    if (itemToDelete) {
      if (deleteItemType === 'project') {
        await deleteProject(itemToDelete._id);
      } else if (deleteItemType === 'script') {
        await deleteScript(itemToDelete._id);
      }
      refetch();
      onDeleteClose();
    }
  };

  const handleAddProject = () => {
    onCreateOpen();
  };

  const handleProjectCreate = async projectName => {
    await createProject(projectName);
    refetch();
    onCreateClose();
  };

  const handleDownloadScript = (scriptId, scriptTitle) => {
    setSelectedScriptId(scriptId);
    setSelectedScriptTitle(scriptTitle);
    onExportOpen();
  };

  const handleExport = async (type) => {
    if (selectedScriptId && selectedScriptTitle) {
      await newDownloadData(selectedScriptId, type, selectedScriptTitle);
    }
  };

  const AttributeBadge = ({ label, value }) => (
    <HStack
      bg={badgeBgColor}
      color={badgeTextColor}
      px={2}
      py={1}
      borderRadius="md"
      fontSize="sm"
    >
      <Text fontWeight="bold">{label}:</Text>
      <Text>{value}</Text>
    </HStack>
  );

  if (projectsLoading || scriptsLoading) {
    return (
      <Container maxW="container.xl" p={8}>
        <Stack spacing={8}>
          {[...Array(3)].map((_, index) => (
            <Skeleton key={index} height="200px" />
          ))}
        </Stack>
      </Container>
    );
  }

  return (
    <Container
      maxW="container.xl"
      p={8}
      bg={bgColor}
      borderRadius="lg"
      boxShadow="xl"
      border={'1px solid'}
      borderColor={borderColor}
    >
      <Heading as="h1" mb={4} color={headingColor}>
        Projects
      </Heading>
      <Divider borderColor={borderColor} mb={8} />

      <AnimatePresence>
        <MotionBox
          variants={containerVariants}
          initial="hidden"
          animate="show"
        >
          <Stack spacing={6}>
            <Button
              leftIcon={<AddIcon />}
              onClick={handleAddProject}
              bg={buttonBgColor}
              color={buttonTextColor}
              transition="all 0.35s ease"
              _hover={{ bg: buttonHoverColor }}
            >
              Add Project
            </Button>

            {projects.map((project, index) => (
              <MotionBox
                key={project._id}
                variants={itemVariants}
                custom={index}
                layout
              >
                <MotionBox
                  p={6}
                  borderRadius="md"
                  boxShadow="lg"
                  bg={cardBgColor}
                  border="1px solid"
                  borderColor={borderColor}
                  shadow={'md'}
                >
                  <HStack justify="space-between" align={'center'} mb={8}>
                    <Heading as="h2" size="md" color={headingColor}>
                      {project.title}
                    </Heading>
                    <IconButton
                      icon={<DeleteIcon />}
                      bg={buttonBgColor}
                      color={iconColor}
                      transition="all 0.35s ease"
                      _hover={{
                        bg: buttonHoverColor,
                        transform: 'scale(1.1)',
                      }}
                      onClick={() => handleDeleteClick(project, 'project')}
                    />
                  </HStack>

                  {project.scripts && project.scripts.length > 0 ? (
                    <Stack spacing={4}>
                      {project.scripts.map(scriptId => (
                        <MotionBox
                          key={scriptId}
                          p={4}
                          borderRadius="md"
                          boxShadow="sm"
                          bg={scriptBgColor}
                        >
                          <HStack justify="space-between" align="flex-start">
                            <VStack align="start" spacing={2} flex={1}>
                              <ChakraLink
                                as={Link}
                                to={`/script/${scriptId}/edit`}
                                fontSize="lg"
                                fontWeight="bold"
                                color={projectLinkColor}
                                transition="all 0.35s ease"
                                _hover={{
                                  color: linkHoverColor,
                                  textDecoration: 'none',
                                }}
                              >
                                {scripts[scriptId]
                                  ? `"${scripts[scriptId].title}"`
                                  : 'Loading...'}
                              </ChakraLink>
                              <Text color={textColor} fontSize="sm">
                                {scripts[scriptId]
                                  ? scripts[scriptId].type
                                  : 'Loading...'}
                              </Text>
                              <HStack spacing={2} wrap="wrap">
                                <AttributeBadge 
                                  label="Duration" 
                                  value={scripts[scriptId]?.duration || 'N/A'} 
                                />
                                <AttributeBadge 
                                  label="Mood" 
                                  value={scripts[scriptId]?.mood || 'N/A'} 
                                />
                                <AttributeBadge 
                                  label="Tone" 
                                  value={scripts[scriptId]?.tone || 'N/A'} 
                                />
                              </HStack>
                            </VStack>
                            <HStack>
                              <Tooltip label="Export Script">
                                <IconButton
                                  icon={<FaFileExport />}
                                  variant={'ghost'}
                                  borderRadius={'md'}
                                  color={buttonTextColor}
                                  transition="all 0.35s ease"
                                  onClick={() => handleDownloadScript(scriptId, scripts[scriptId]?.title)}
                                  _hover={{
                                    bg: buttonHoverColor,
                                    transform: 'scale(1.1)',
                                  }}
                                />
                              </Tooltip>
                              <Tooltip label="Export Ad">
                                <IconButton
                                  icon={<FaAd />}
                                  variant={'ghost'}
                                  borderRadius={'md'}
                                  color={buttonTextColor}
                                  transition="all 0.35s ease"
                                  onClick={() => console.log('Export Ad')}
                                  _hover={{
                                    bg: buttonHoverColor,
                                    transform: 'scale(1.1)',
                                  }}
                                />
                              </Tooltip>
                              <Menu>
                                <MenuButton
                                  as={IconButton}
                                  icon={<FaEllipsis />}
                                  variant={'ghost'}
                                  borderRadius={'md'}
                                  color={iconColor}
                                  transition="all 0.35s ease"
                                  _hover={{
                                    bg: buttonHoverColor,
                                    transform: 'scale(1.1)',
                                  }}
                                />
                                <MenuList bg={cardBgColor}>
                                  <MenuItem
                                    as={Link}
                                    to={`/script/${scriptId}/edit`}
                                    icon={<EditIcon />}
                                    bg={cardBgColor}
                                    _hover={{ bg: buttonHoverColor }}
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuDivider />
                                  <MenuItem
                                    icon={<DeleteIcon />}
                                    onClick={() => handleDeleteClick(scripts[scriptId], 'script')}
                                    bg={cardBgColor}
                                    _hover={{ bg: buttonHoverColor }}
                                  >
                                    Delete
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </HStack>
                          </HStack>
                        </MotionBox>
                      ))}
                    </Stack>
                  ) : (
                    <MotionBox 
                      textAlign="left" 
                      mt={8}
                      border="1px solid"
                      borderColor={borderColor}
                      p={4}
                      borderRadius="md"
                    >
                      <Text color={textColor} mb={2}>
                        There are no ads yet.
                      </Text>
                      <Button
                        as={Link}
                        to="/generate-ad"
                        bg={buttonBgColor}
                        color={buttonTextColor}
                        transition="all 0.35s ease"
                        _hover={{ bg: buttonHoverColor }}
                      >
                        Generate Ad
                      </Button>
                    </MotionBox>
                  )}
                </MotionBox>
              </MotionBox>
            ))}
          </Stack>
        </MotionBox>
      </AnimatePresence>

      <DeleteItemModal
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        itemName={itemToDelete?.title || ''}
        itemType={deleteItemType}
        onDelete={handleDeleteConfirm}
      />

      <CreateProjectModal
        isOpen={isCreateOpen}
        onClose={onCreateClose}
        onProjectCreate={handleProjectCreate}
        isLoading={isCreatingProject}
      />

      <ExportModal
        isOpen={isExportOpen}
        onClose={onExportClose}
        onExport={handleExport}
        mediaType={'Script'}
      />
    </Container>
  );
};

export default Projects;
