import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  FormControl,
  Text,
  Stack,
  Icon,
  Tooltip,
  Button,
  ButtonGroup,
  Box,
  useDisclosure,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import SegmentedProgressBar from '../SegmentedProgressBar/SegmentedProgressBar';
import CustomSelect from '../CustomSelect/CustomSelect';
import CreateProjectModal from '../CreateProjectModal/CreateProjectModal';
import useCreateProject from '../../hooks/useCreateProject';
import useGetProjects from '../../hooks/useGetProjects';
import DOMPurify from 'dompurify';
import { FaArrowRight } from 'react-icons/fa';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { MotionBox, MotionFlex } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { useAdGeneration } from '../../providers/AdGenerationProvider';
import { debounce } from 'lodash';

const ScriptProjectDetails = () => {
  const bgColor = useColorModeValue('beige.200', 'gunmetal.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const labelColor = useColorModeValue('gray.600', '#A0AEC0');
  const iconColor = useColorModeValue('gray.500', 'gray.400');
  const buttonBgColor = useColorModeValue('gradBeige', 'gradBlack');
  const buttonHoverBgColor = useColorModeValue('gradBeigeHover', 'gradBlackHover');
  const button2BgColor = useColorModeValue('gradGunmetal', 'gradGunmetal');
  const button2HoverBgColor = useColorModeValue('gradGunmetalHover', 'gradGunmetalHover');
  const buttonTextColor = useColorModeValue('white', 'white');
  const optionBgColor = useColorModeValue('beige.100', 'whiteAlpha.100');
  const optionBorderColor = useColorModeValue('gray.200', 'gray.500');
  const optionSelectedBgColor = useColorModeValue('beige.200', 'beige.300');
  const optionSelectedColor = useColorModeValue('ffffff', '#FFFFFF');
  const optionHoverBgColor = useColorModeValue('gray.100', 'gunmetal.400');
  const optionHoverColor = useColorModeValue('ffffff', '#ffffff');
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  
  const { createProject, isLoading: isCreatingProject } = useCreateProject();
  const { projects, setProjects, isLoading: isLoadingProjects } = useGetProjects();
  const { totalSteps, setProjectDetails, projectDetails } = useAdGeneration();
  
  // Local State for Selected Project
  const [selectedProject, setSelectedProject] = useState({ 
    value: '',
    label: '',
  });

  // List of Content Types
  const [contentTypes, setContentTypes] = useState([ 
    {
      label: 'Voiceover',
      isSelected: false,
      isDisabled: false,
    },
    {
      label: 'Music',
      isSelected: false,
      isDisabled: false,
    },
    {
      label: 'Video',
      isSelected: false,
      isDisabled: true,
    },
  ]);

  const updateAdGenerationContext = useCallback(
    // Update the ad generation context
    (newProject, newMediaElements) => {
      const debouncedUpdate = debounce(() => {
        setProjectDetails({
          project: newProject,
          mediaElements: newMediaElements
        });
      }, 300);

      debouncedUpdate();

      return () => debouncedUpdate.cancel();
    },
    [setProjectDetails]
  );

  const handleNext = () => {
    // Navigate to the next step
    navigate('/script-details');
  };

  const handleBack = () => {
    // Navigate to the previous step
    navigate(-1);
  };

  const handleChange = option => {
    // Open the create project modal
    if (option.value === 'new-project') {
      onOpen();
    } else {
      const newProject = { id: option.value, name: option.label };
      setSelectedProject(option);
      updateAdGenerationContext(newProject);
    }
  };

  const handleButtonClick = index => {
    // Toggle the selected state of the content type
    const updatedContentTypes = contentTypes.map((item, i) =>
      i === index ? { ...item, isSelected: !item.isSelected } : item
    );

    setContentTypes(updatedContentTypes);
    const newContentTypes = updatedContentTypes
      .filter(item => item.isSelected)
      .map(item => item.label);
    
    updateAdGenerationContext(projectDetails.project, newContentTypes);
  };

  const handleProjectCreate = async (projectName) => {
    console.log("Project Name", projectName);
    const sanitizedProjectName = DOMPurify.sanitize(projectName);
    console.log("Sanitized Project Name", sanitizedProjectName);
    
    try {
      const newProject = await createProject(sanitizedProjectName);
      console.log("New Project", newProject);
  
      if (newProject) {
        const projectObject = {
          value: newProject._id,
          label: sanitizedProjectName,
        };
  
        // Ensure setProjects is correctly used
        setProjects(prevProjects => [...prevProjects, projectObject]);
        setSelectedProject(projectObject);
  
        const newProjectForContext = { id: projectObject.value, name: projectObject.label };
        updateAdGenerationContext(newProjectForContext);
  
        onClose();
        return true;
      }
    } catch (error) {
      console.error('Error creating project:', error);
    }
  
    return false;
  };
  

  // Memoize project options to ensure they update when projects change
  const projectOptions = useMemo(() => {
    const options = projects.map(project => ({
      value: project._id,
      label: project.title,
    }));

    // Add "Create New Project" option at the beginning
    return [{ value: 'new-project', label: 'Create New Project' }, ...options];
  }, [projects]);

  return (
    <Box
      p={8}
      bg={bgColor}
      color={textColor}
      borderRadius="md"
      maxW="1200px"
      mx="auto"
      w="full"
    >
      <MotionFlex
        flexDir={'column'}
        justify={'center'}
        align={'stretch'}
        key="step1"
        transition={{ duration: 0.5 }}
        flex={1}
        p={{ base: 4, md: 8 }}
      >
        <Stack direction={'row'} align={'center'} mb={2}>
          <Text fontSize={{ base: 'lg', md: '2xl' }}>Step 1</Text>
          <Icon as={FaArrowRight} w={6} h={6} color={iconColor} mx={4} />
          <Text fontSize={{ base: 'lg', md: '2xl' }}>Project Details</Text>
        </Stack>
        <Text mb={8}>Select the elements you would like to use.</Text>

        <SegmentedProgressBar
          currentStep={'1'}
          totalSteps={totalSteps}
        />

        <FormControl id="select-project" isRequired my={4}>
          <HStack align={'center'} mb={2}>
            <Text fontSize={{ base: 'sm', md: 'md' }} color={labelColor}>
              Select a Project
            </Text>
            <Tooltip
              label="Projects are used to organize your content and media elements. You can create a new project or select an existing one."
              aria-label="Project Name Tooltip"
            >
              <Icon
                as={QuestionOutlineIcon}
                ml={2}
                cursor="pointer"
                color={iconColor}
              />
            </Tooltip>
          </HStack>
          <CustomSelect
            label="Select Project"
            placeholder="Select Project"
            options={projectOptions}
            value={selectedProject}
            onSelectNewProject={onOpen}
            onChange={handleChange}
            isLoading={isLoadingProjects}
          />
        </FormControl>

        <FormControl id="media-elements" isRequired my={4}>
          <HStack align={'center'} mb={2}>
            <Text fontSize={{ base: 'sm', md: 'md' }} color={labelColor}>
              What elements would you like to AI Generate for your content?
            </Text>
            <Tooltip
              label="Select the elements you would like to use. You can select multiple elements."
              aria-label="Media Elements Tooltip"
            >
              <Icon
                as={QuestionOutlineIcon}
                ml={2}
                cursor="pointer"
                color={iconColor}
              />
            </Tooltip>
          </HStack>
          <MotionFlex
            direction={{ base: 'column', md: 'row' }}
            align="center"
            justify="stretch"
            rounded="xl"
            p={4}
            cursor="pointer"
            bg={optionBgColor}
            color={textColor}
            gap={4}
            border={'1px solid'}
            borderColor={optionBorderColor}
          >
            {contentTypes.map((option, index) => (
              <Tooltip
                key={option.label}
                label="Coming Soon"
                isDisabled={!option.isDisabled}
              >
                <MotionBox
                  transition={{ duration: 0.2 }}
                  onClick={() => !option.isDisabled && handleButtonClick(index)}
                  rounded="xl"
                  p={4}
                  bg={option.isSelected ? optionSelectedBgColor : 'transparent'}
                  color={option.isDisabled ? 'gray.500' : optionSelectedColor}
                  borderWidth="1px"
                  borderColor={
                    option.isSelected ? optionSelectedBgColor : optionBorderColor
                  }
                  _hover={
                    option.isDisabled
                      ? { bg: 'gray.500', color: 'gray.400' }
                      : { bg: optionHoverBgColor, color: optionHoverColor }
                  }
                  cursor={
                    option.isDisabled ? 'not-allowed' : 'pointer'
                  }
                  w={{ base: '100%', md: 'auto' }}
                >
                  {option.label}
                </MotionBox>
              </Tooltip>
            ))}
          </MotionFlex>
        </FormControl>

        <ButtonGroup
          size={'lg'}
          spacing="6"
          isAttached
          borderRadius={'md'}
          mt={10}
        >
          <Button
            bg={buttonBgColor}
            _hover={{ bg: buttonHoverBgColor }}
            onClick={handleBack}
            color={buttonTextColor}
          >
            Back
          </Button>
          <Button
            bg={button2BgColor}
            _hover={{ bg: button2HoverBgColor }}
            onClick={handleNext}
            isDisabled={!selectedProject.value || !contentTypes.some(item => item.isSelected)}
            color={buttonTextColor}
          >
            Next
          </Button>
        </ButtonGroup>

      </MotionFlex>

      <CreateProjectModal
        isOpen={isOpen}
        onClose={onClose}
        onCreateProject={handleProjectCreate}
        isLoading={isCreatingProject}
      />
    </Box>
  );
};

export default ScriptProjectDetails;
