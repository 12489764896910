import React from 'react';
import AdStart from '../components/AdStart/AdStart';
import WelcomeModal from '../components/WelcomeModal/WelcomeModal';

const GenerateAd = () => {
  // If any of the context values are null, redirect to that component after ad start

  return (
    <>
      <WelcomeModal />
      <AdStart />
    </>
  );
};

export default GenerateAd;
