import React from 'react';
import { Box, Text, VStack, Heading, Button, IconButton, Skeleton, useColorModeValue } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';

const ScriptIdeas = ({ suggestions, onSelectIdea, onRegenerate, loading }) => {
  // Color mode values
  const bgColor = useColorModeValue('beige.200', 'gunmetal.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.100', 'gunmetal.500');
  const buttonBgColor = useColorModeValue('beige.400', 'gunmetal.500');
  const buttonHoverBgColor = useColorModeValue('beige.300', 'gunmetal.400');
  const buttonTextColor = useColorModeValue('white', 'white');
  const iconButtonBgColor = useColorModeValue('beige.100', 'whiteAlpha.200');
  const iconButtonHoverBgColor = useColorModeValue('white', 'whiteAlpha.300');

  return (
    <Box mt={8} bg={bgColor} color={textColor} p={4} borderRadius="md">
      <Heading as="h2" size="lg" mb={4}>
        Script Ideas
        <IconButton
          icon={<RepeatIcon />}
          onClick={onRegenerate}
          ml={4}
          isLoading={loading}
          bg={iconButtonBgColor}
          _hover={{ bg: iconButtonHoverBgColor }}
        />
      </Heading>
      <VStack spacing={4} align="start">
        {loading
          ? Array.from({ length: 6 }).map((_, index) => (
              <Skeleton key={index} height="125px" width="100%" />
            ))
          : suggestions.map((suggestion, index) => (
              <Box
                key={index}
                p={4}
                border="1px solid"
                borderColor={borderColor}
                borderRadius="md"
                w="full"
                bg={bgColor}
              >
                <Text fontWeight="bold">{suggestion.title}</Text>
                <Text>{suggestion.description}</Text>
                <Button 
                  mt={2} 
                  onClick={() => onSelectIdea(suggestion)}
                  bg={buttonBgColor}
                  _hover={{ bg: buttonHoverBgColor }}
                  color={buttonTextColor}
                >
                  Select
                </Button>
              </Box>
            ))}
      </VStack>
    </Box>
  );
};

export default ScriptIdeas;