import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  HStack,
  VStack,
  Icon,
  Stack,
  Button,
  Flex,
  Image,
  Skeleton,
  Select,
  ButtonGroup,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import SegmentedProgressBar from '../SegmentedProgressBar/SegmentedProgressBar';
import { FaArrowRight } from 'react-icons/fa';
import { useGetVoiceActors } from '../../hooks/useGetVoiceActors';
import { useAdGeneration } from '../../providers/AdGenerationProvider';
import { useNavigate } from 'react-router-dom';

const AudioSelectAVoice = () => {
  const bgColor = useColorModeValue('beige.200', 'gunmetal.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const iconColor = useColorModeValue('gray.500', 'gunmetal.400');
  const rowBgColor = useColorModeValue('beige.200', 'blackAlpha.200');
  const cardBgColor = useColorModeValue('beige.100', 'gradBlack');
  const cardBgHoverColor = useColorModeValue('white', 'gradBlackHover');
  const cardHeadingColor = useColorModeValue('gunmetal.500', 'beige.300');
  const selectButtonBgColor = useColorModeValue('transparent', 'transparent');
  const selectButtonBorderColor = useColorModeValue('gunmetal.500', 'white');
  const selectedButtonBgColor = useColorModeValue('gunmetal.500', 'beige.300');
  const selectedButtonBorderColor = useColorModeValue('gunmetal.500', 'beige.300');
  const selectButtonTextColor = useColorModeValue('gunmetal.500', 'white');
  const selectButtonHoverTextColor = useColorModeValue('white', 'white');
  const selectedButtonHoverTextColor = useColorModeValue('white', 'white');
  const selectedButtonTextColor = useColorModeValue('white', 'white');
  const selectedButtonHoverBgColor = useColorModeValue('gunmetal.500', 'beige.300');
  const selectButtonHoverBgColor = useColorModeValue('gunmetal.500', 'beige.300');
  const badgeBgColor = useColorModeValue('beige.200', 'beige.200');
  const badgeTextColor = useColorModeValue('gunmetal.500', 'gunmetal.600');
  const badgeHoverBgColor = useColorModeValue('beige.300', 'beige.300');
  const loadMoreBgColor = useColorModeValue('beige.200', 'gunmetal.500');
  const loadMoreTextColor = useColorModeValue('gunmetal.500', 'white');
  const loadMoreHoverBgColor = useColorModeValue('beige.300', 'gunmetal.400');
  const backButtonBgColor = useColorModeValue('gradBeige', 'gradBlack');
  const backButtonHoverBgColor = useColorModeValue('gradBeigeHover', 'gradBlackHover');
  const nextButtonBgColor = useColorModeValue('gradGunmetal', 'gradGunmetal');
  const nextButtonHoverBgColor = useColorModeValue('gradGunmetalHover', 'gradGunmetalHover');

  const { voiceActors, loading } = useGetVoiceActors();
  const { setVoiceActor, voiceActor, totalSteps, scriptDetails, scriptIdea, adType } = useAdGeneration();

  const navigate = useNavigate();
  const toast = useToast();
  
  const [filters, setFilters] = useState({
    gender: '',
    ageRange: '',
    emotion: '',
    style: '',
    tone: '',
  });

  const [showAll, setShowAll] = useState(false);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const filteredActors = voiceActors.filter(actor => {
    return (
      (!filters.gender || actor.gender === filters.gender) &&
      (!filters.ageRange || actor.ageRange === filters.ageRange) &&
      (!filters.emotion || actor.deliveryEmotion === filters.emotion) &&
      (!filters.style || actor.deliveryStyle === filters.style) &&
      (!filters.tone || actor.deliveryTone === filters.tone)
    );
  });

  const suggestedActors = filteredActors.filter(actor => 
    ['Bernice', 'Andrew', 'Claire'].includes(actor.name)
  );

  const handleLoadMore = () => {
    setShowAll(true);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleNext = () => {
    if (voiceActor) {
      navigate('/script-generating');
    } else {
      toast({
        title: 'Please select a voice',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSelectActor = (actor) => {
    if (voiceActor?.name === actor.name) {
      setVoiceActor(null);
    } else {
      setVoiceActor(actor);
    }
  };

  useEffect(() => {
    console.log('scriptDetails', scriptDetails); 
    console.log('scriptIdea', scriptIdea);
    console.log('adType', adType);
    console.log('voiceActor', voiceActor);
  }, [voiceActor, scriptDetails, scriptIdea, adType]);

  return (
    <Box
      p={8}
      bg={bgColor}
      color={textColor}
      borderRadius="md"
      maxW="1200px"
      mx="auto"
      w="full"
    >
      {/* // Section Step // */}
      <Stack direction={'row'} align={'center'} mb={2}>
        <Text fontSize={{ base: 'lg', md: '2xl' }}>Step 3</Text>
        <Icon as={FaArrowRight} w={6} h={6} color={iconColor} mx={4} />
        <Text fontSize={{ base: 'lg', md: '2xl' }}>Select a Voice</Text>
      </Stack>
      <Text mb={8}>Choose your voice for your ad.</Text>

      <SegmentedProgressBar currentStep={3} totalSteps={totalSteps} />

      {/* // Filter Options // */}
      <HStack spacing={4} mb={8}>
        <Select placeholder="Gender" name="gender" onChange={handleFilterChange}>
          <option value="Female">Female</option>
          <option value="Male">Male</option>
        </Select>
        <Select placeholder="Age Range" name="ageRange" onChange={handleFilterChange}>
          <option value="20-30">20-30</option>
          <option value="25-35">25-35</option>
          <option value="30-40">30-40</option>
          <option value="35-45">35-45</option>
          <option value="40-50">40-50</option>
          <option value="65+">65+</option>
        </Select>
        <Select placeholder="Emotion" name="emotion" onChange={handleFilterChange}>
          <option value="Friendly">Friendly</option>
          <option value="Calm">Calm</option>
          <option value="Confident">Confident</option>
          <option value="Approachable">Approachable</option>
          <option value="Heartfelt">Heartfelt</option>
          <option value="Determined">Determined</option>
        </Select>
        <Select placeholder="Style" name="style" onChange={handleFilterChange}>
          <option value="Conversational">Conversational</option>
          <option value="Professional">Professional</option>
          <option value="Gentle">Gentle</option>
          <option value="Energetic">Energetic</option>
          <option value="Authoritative">Authoritative</option>
        </Select>
        <Select placeholder="Tone" name="tone" onChange={handleFilterChange}>
          <option value="Warm">Warm</option>
          <option value="Soft">Soft</option>
          <option value="Smooth">Smooth</option>
          <option value="Clear">Clear</option>
          <option value="Deep">Deep</option>
          <option value="Crisp">Crisp</option>
          <option value="Bright">Bright</option>
        </Select>
      </HStack>

      {showAll ? null : (
        <Text fontSize="xl" mb={4} fontWeight="bold">
          Suggested for you
        </Text>
      )}

      <VStack spacing={4} align="stretch" mt={8} bg={rowBgColor}>
        {loading
          ? Array.from({ length: 6 }).map((_, index) => (
              <HStack key={index} spacing={4} bg={cardBgColor} borderRadius={'md'} p={4}>
                <Skeleton height="150px" width="150px" borderRadius="full" />
                <VStack align="stretch" spacing={0} p={4} maxW={'300px'}>
                  <Skeleton height="20px" width="100px" />
                  <Skeleton height="40px" width="100%" />
                  <Skeleton height="20px" width="100px" />
                  <Skeleton height="60px" width="100%" />
                </VStack>
                <VStack>
                  <Skeleton height="50px" width="200px" />
                  <HStack mt={4} spacing={2}>
                    <Skeleton height="40px" width="80px" borderRadius="full" />
                    <Skeleton height="40px" width="80px" borderRadius="full" />
                    <Skeleton height="40px" width="80px" borderRadius="full" />
                  </HStack>
                </VStack>
              </HStack>
            ))
          : (showAll ? filteredActors : suggestedActors).map((actor, index) => (
              <HStack
                key={index}
                spacing={4}
                bg={cardBgColor}
                borderRadius={'md'}
                p={4}
                _hover={{ bg: cardBgHoverColor }}
              >
                {/* // Image // */}
                <Image
                  src={actor.image}
                  alt={actor.name}
                  pl={2}
                  maxH={'100%'}
                  w={'150px'}
                  borderRadius={'full'}
                />

                {/* // Description // */}
                <VStack align={'stretch'} spacing={0} p={4} maxW={'300px'}>
                  <Text fontSize={'md'} textTransform={'uppercase'} color={cardHeadingColor}>
                    Name
                  </Text>
                  <Text fontSize={'lg'} mb={4} fontWeight={'bold'}>
                    {actor.name}
                  </Text>

                  <Text fontSize={'md'} textTransform={'uppercase'} color={cardHeadingColor}>
                    Description
                  </Text>
                  <Text fontSize={'lg'}>{actor.description}</Text>
                </VStack>

                <VStack>
                  <HStack>
                    {/* // Audio Player // */}
                    <audio controls>
                      <source src={actor.sampleAudio} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>

                    <Button 
                      variant={voiceActor?.name === actor.name ? 'solid' : 'outline'} 
                      borderRadius={'full'}
                      w={'100px'}
                      py={6}
                      bg={voiceActor?.name === actor.name ? selectedButtonBgColor : selectButtonBgColor}
                      borderColor={voiceActor?.name === actor.name ? selectedButtonBorderColor : selectButtonBorderColor}
                      color={voiceActor?.name === actor.name ? selectedButtonTextColor : selectButtonTextColor}
                      _hover={{ bg: voiceActor?.name === actor.name ? selectedButtonHoverBgColor : selectButtonHoverBgColor, color: voiceActor?.name === actor.name ? selectedButtonHoverTextColor : selectButtonHoverTextColor }}
                      onClick={() => handleSelectActor(actor)}
                    >
                      {voiceActor?.name === actor.name ? 'Selected' : 'Select'}
                    </Button>
                  </HStack>

                  <HStack mt={4} spacing={2} justify={'space-between'} w={'full'}>
                    {/* // Descriptor Badge // */}
                    <Flex
                      bg={badgeBgColor}
                      flex={1}
                      color={badgeTextColor}
                      px={4}
                      py={2}
                      borderRadius={'full'}
                      align={'center'}
                      justify={'center'}
                      _hover={{ bg: badgeHoverBgColor }}
                      _active={{ bg: badgeHoverBgColor }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      <Text fontSize={'md'} fontWeight={'700'}>
                        {actor.deliveryEmotion}
                      </Text>
                    </Flex>

                    {/* // Descriptor Badge // */}
                    <Flex
                      bg={badgeBgColor}
                      flex={1}
                      color={badgeTextColor}
                      px={4}
                      py={2}
                      borderRadius={'full'}
                      align={'center'}
                      justify={'center'}
                      _hover={{ bg: badgeHoverBgColor }}
                      _active={{ bg: badgeHoverBgColor }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      <Text fontSize={'md'} fontWeight={'700'}>
                        {actor.deliveryTone}
                      </Text>
                    </Flex>

                    {/* // Descriptor Badge // */}
                    <Flex
                      bg={badgeBgColor}
                      flex={1}
                      color={badgeTextColor}
                      px={4}
                      py={2}
                      borderRadius={'full'}
                      align={'center'}
                      justify={'center'}
                      _hover={{ bg: badgeHoverBgColor }}
                      _active={{ bg: badgeHoverBgColor }}
                      _focus={{ boxShadow: 'none' }}
                    >
                      <Text fontSize={'md'} fontWeight={'700'}>
                        {actor.deliveryStyle}
                      </Text>
                    </Flex>
                  </HStack>
                </VStack>
              </HStack>
            ))}

        {!showAll && (
          <Button onClick={handleLoadMore} mt={4} bg={loadMoreBgColor} _hover={{ bg: loadMoreHoverBgColor }} color={loadMoreTextColor}>
            Load More
          </Button>
        )}
      </VStack>

      <ButtonGroup
        size={'lg'}
        spacing="6"
        isAttached
        borderRadius={'md'}
        mt={10}
      >
        <Button
          bg={backButtonBgColor}
          _hover={{ bg: backButtonHoverBgColor }}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          bg={nextButtonBgColor}
          _hover={{ bg: nextButtonHoverBgColor }}
          onClick={handleNext}
          isDisabled={!voiceActor}
        >
          Next
        </Button>
      </ButtonGroup>
      
    </Box>
  );
};

export default AudioSelectAVoice;
