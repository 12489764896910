import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Image,
  VStack,
  Tooltip,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useAdGeneration } from '../../providers/AdGenerationProvider';
import { useNavigate } from 'react-router-dom';

const validAdTypes = ['Audio Ad', 'Full Control', 'Video Ad'];

const AdStart = () => {
  const bgColor = useColorModeValue('beige.100', 'gunmetal.700');
  const cardBgColor = useColorModeValue('beige.200', 'gunmetal.500');
  const cardHoverBgColor = useColorModeValue('beige.300', 'gunmetal.400');
  const headingColor = useColorModeValue('gray.800', 'white');
  const textColor = useColorModeValue('gray.600', 'gray.300');
  const buttonBgColor = useColorModeValue('gunmetal.500', 'gunmetal.500');
  const buttonHoverBgColor = useColorModeValue('gunmetal.400', 'gunmetal.400');
  const borderColor = useColorModeValue('beige.100', 'gunmetal.300');
  const shadowColor = useColorModeValue('rgba(0, 0, 0, 0.1)', 'rgba(255, 255, 255, 0.1)');
  
  const navigate = useNavigate();
  const toast = useToast();
  const { setAdType } = useAdGeneration();
  const [selectedAdType, setSelectedAdType] = useState(null);

  const handleAdClick = (adType) => {
    if (adType !== 'Video Ad') {
      setSelectedAdType(adType);
    }
  };

  const handleGetStartedClick = () => {
    if (validAdTypes.includes(selectedAdType)) {
      setAdType(selectedAdType);
      
      if (selectedAdType === 'Audio Ad') {
        navigate('/project-details');
      } else if (selectedAdType === 'Full Control') {
        navigate('/script-editor');
      }
    } else {
      toast({
        title: 'Invalid Ad Type',
        description: 'Please select a valid ad type.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    console.log('selectedAdType', selectedAdType)
  }, [selectedAdType])

  return (
    <Box
      p={8}
      bg={bgColor}
      color={textColor}
      borderRadius="md"
      maxW="1200px"
      mx="auto"
      w="full"
      textAlign="center"
    >
      <Heading size="xl" mb={4} color={headingColor}>
        Let's Get Creating
      </Heading>
      <Text fontSize="lg" mb={8}>
        Select the type of ad you would like to create.
      </Text>

      <VStack spacing={8}>
        {/* Audio Ad */}
        <Flex
          w="full"
          bg={cardBgColor}
          borderRadius="md"
          align="center"
          p={4}
          onClick={() => handleAdClick('Audio Ad')}
          cursor="pointer"
          _hover={{ bg: cardHoverBgColor }}
          borderWidth={selectedAdType === 'Audio Ad' ? '1px' : '0'}
          borderColor={selectedAdType === 'Audio Ad' ? borderColor : 'transparent'}
          boxShadow={selectedAdType === 'Audio Ad' ? `0 0 10px 5px ${shadowColor}` : 'none'}
        >
          <Image
            src="https://via.placeholder.com/100"
            alt="Audio Ad"
            borderRadius="md"
            boxSize="100px"
            objectFit="cover"
            mr={4}
          />
          <VStack align="start" spacing={1}>
            <Heading size="md" color={headingColor}>Audio Ad</Heading>
            <Text maxW={'400px'} textAlign={'left'}>
              This is a guided process that helps determine the type of project, theme, tone, and other
              details.
            </Text>
          </VStack>
        </Flex>

        {/* Full Control */}
        <Flex
          w="full"
          bg={cardBgColor}
          borderRadius="md"
          align="center"
          p={4}
          onClick={() => handleAdClick('Full Control')}
          cursor="pointer"
          _hover={{ bg: cardHoverBgColor }}
          borderWidth={selectedAdType === 'Full Control' ? '2px' : '0'}
          borderColor={selectedAdType === 'Full Control' ? borderColor : 'transparent'}
          boxShadow={selectedAdType === 'Full Control' ? `0 0 0 2px ${shadowColor}` : 'none'}
        >
          <Image
            src="https://via.placeholder.com/100"
            alt="Full Control"
            borderRadius="md"
            boxSize="100px"
            objectFit="cover"
            mr={4}
          />
          <VStack align="start" spacing={1}>
            <Heading size="md" color={headingColor}>Full Control</Heading>
            <Text maxW={'400px'} textAlign={'left'}>
              This will start you off with a blank slate.
            </Text>
          </VStack>
        </Flex>

        {/* Video Ad */}
        <Flex
          w="full"
          bg={cardBgColor}
          borderRadius="md"
          align="center"
          p={4}
          opacity={0.5}
        >
          <Tooltip label="Coming Soon" aria-label="Coming Soon">
            <Image
              src="https://via.placeholder.com/100"
              alt="Video Ad"
              borderRadius="md"
              boxSize="100px"
              objectFit="cover"
              mr={4}
            />
          </Tooltip>
          <VStack align="start" spacing={1}>
            <Heading size="md" color={useColorModeValue('gray.400', 'gray.400')}>
              Video Ad
            </Heading>
            <Text maxW={'400px'} textAlign={'left'}>
              This helps determine the type of project, theme, tone, and other
              details.
            </Text>
          </VStack>
        </Flex>
      </VStack>

      <Button
        mt={8}
        bg={buttonBgColor}
        color="white"
        size="lg"
        px={8}
        py={6}
        borderRadius="md"
        onClick={handleGetStartedClick}
        isDisabled={!selectedAdType}
        _hover={{ bg: buttonHoverBgColor }}
      >
        Get Started
      </Button>
    </Box>
  );
};

export default AdStart;
