import React from 'react';
import {
  Flex,
  Icon,
  Text,
  Tooltip,
  Spinner,
  Badge,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { PiCoinVerticalLight } from 'react-icons/pi';

const CreditsInfo = ({ user, subscription, loading }) => {
  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const alignItems = useBreakpointValue({ base: 'flex-start', md: 'center' });
  const textSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const badgeMargin = useBreakpointValue({ base: '0', md: '4' });

  const borderColor = useColorModeValue('gunmetal.500', 'white');
  const bgColor = useColorModeValue('transparent', 'transparent');
  const textColor = useColorModeValue('gunmetal.500', 'white');
  const iconColor = useColorModeValue('gunmetal.500', 'white');

  if (loading) {
    return <Spinner color={textColor} />;
  }

  return (
    <Flex
      direction={flexDirection}
      align={alignItems}
      minW={'300px'}
      flex={1}
      p={4}
    >
      {subscription && (
        <Tooltip
          label="Each script or voiceover consumes credits. Your credits renew each month."
          aria-label="Credits Remaining"
        >
          <Flex
            align={'center'}
            borderColor={borderColor}
            borderWidth={'1px'}
            rounded={'lg'}
            p={2}
            mb={{ base: 2, md: 0 }}
            bg={bgColor}
          >
            <Icon
              as={PiCoinVerticalLight}
              w={6}
              h={6}
              mr={1}
              color={iconColor}
            />
            <Text fontSize={textSize} mr={1} color={textColor}>
              {user && user?.purchasedCredits}
            </Text>
            <Text fontSize={textSize} color={textColor}>
              Credits Remaining
            </Text>
          </Flex>
        </Tooltip>
      )}
      {subscription ? (
        <Tooltip
          label="Your current subscription plan. Upgrade for more features."
          aria-label="Subscription Plan"
        >
          <Badge colorScheme="green" ml={badgeMargin} mt={{ base: 2, md: 0 }}>
            {subscription && subscription?.planName}
          </Badge>
        </Tooltip>
      ) : (
        <Badge colorScheme="red" ml={badgeMargin} mt={{ base: 2, md: 0 }}>
          No Subscription
        </Badge>
      )}
    </Flex>
  );
};

export default CreditsInfo;
