import React, { useState, useEffect } from 'react';
import {
  Box,
  Link,
  Flex,
  VStack,
  Heading,
  Text,
  Divider,
  Stack,
  Image,
  Spacer,
  useDisclosure,
  IconButton,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import {
  FaFeatherAlt,
  FaCog,
  FaFolderOpen,
  FaFileAlt,
  FaQuestion,
  FaSignOutAlt,
} from 'react-icons/fa';
import { AiFillDashboard } from 'react-icons/ai';
import Logo from '../../assets/Epicly-Logo-Wht.svg';

const Nav = ({ user, logout }) => {
  const { isOpen, onToggle } = useDisclosure();
  const [showHamburger, setShowHamburger] = useState(false);
  const navWidth = useBreakpointValue({ base: '100%', md: '64' });
  const showContent = useBreakpointValue({ base: false, md: true });

  const bgColor = useColorModeValue('lightMode.bgSecondary', 'darkMode.bgSecondary');
  const textColor = useColorModeValue('lightMode.text', 'darkMode.text');
  const iconColor = useColorModeValue(
    'lightMode.text', 'darkMode.primary'
  );
  const hoverBgColor = useColorModeValue(
    'lightMode.secondary',
    'darkMode.secondary'
  );

  const handleResize = () => {
    setShowHamburger(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navItems = [
    {
      title: 'Dashboard',
      href: '/',
      icon: <AiFillDashboard />,
      isDisabled: false,
    },
    {
      title: 'Generate Ad',
      href: '/generate-ad',
      icon: <FaFeatherAlt />,
      isDisabled: false,
    },
  ];

  const libraryItems = [
    {
      title: 'Projects',
      href: '/projects',
      icon: <FaFolderOpen />,
      isDisabled: false,
    },
    {
      title: 'Scripts',
      href: '/scripts',
      icon: <FaFileAlt />,
      isDisabled: false,
    },
  ];

  const navContent = (
    <VStack align="start" p={6} spacing={6} height={'100%'}>
      <Link href="/dashboard">
        <Image src={Logo} alt="logo" w="full" maxH="full" />
      </Link>
      <Heading
        as="h2"
        size="sm"
        textTransform={'uppercase'}
        fontWeight={'normal'}
        color={textColor}
      >
        Menu
      </Heading>
      {navItems.map(navItem => (
        <Flex direction="row" align="center" key={navItem.title}>
          <Box color={navItem.isDisabled ? 'gray.500' : iconColor}>
            {navItem.icon}
          </Box>
          {navItem.isDisabled ? (
            <Tooltip
              label="Coming soon!"
              aria-label="Coming soon!"
              placement="right"
            >
              <Text color="gray.500" ml={4}>
                {navItem.title}
              </Text>
            </Tooltip>
          ) : (
            <Link
              href={navItem.href}
              ml={4}
              color={textColor}
              _hover={{ color: hoverBgColor }}
            >
              {navItem.title}
            </Link>
          )}
        </Flex>
      ))}
      <Divider />
      <Stack>
        <Heading
          as="h2"
          size="sm"
          textTransform={'uppercase'}
          fontWeight={'normal'}
          color={textColor}
        >
          Library
        </Heading>
      </Stack>
      {libraryItems.map(libraryItem => (
        <Flex direction="row" align="center" key={libraryItem.title}>
          <Box color={libraryItem.isDisabled ? 'gray.500' : iconColor}>
            {libraryItem.icon}
          </Box>
          {libraryItem.isDisabled ? (
            <Text color="gray.500" ml={4}>
              {libraryItem.title}
            </Text>
          ) : (
            <Link
              href={libraryItem.href}
              ml={4}
              color={textColor}
              _hover={{ color: hoverBgColor }}
            >
              {libraryItem.title}
            </Link>
          )}
        </Flex>
      ))}
      <Spacer />
      <Flex flexDir={'column'} justify={'flex-end'} width={'100%'}>
        <Divider mb={5} />
        <Flex direction="row" align="center" mb={4}>
          <FaCog color={iconColor} />
          <Link
            href="/settings"
            ml={4}
            color={textColor}
            _hover={{ color: hoverBgColor }}
          >
            Settings
          </Link>
        </Flex>
        <Flex direction="row" align="center" mb={4}>
          <FaQuestion color={iconColor} />
          <Link
            href="/help"
            ml={4}
            color={textColor}
            _hover={{ color: hoverBgColor }}
          >
            Help
          </Link>
        </Flex>
        <Flex direction="row" align="center" mb={4}>
          <FaSignOutAlt color={iconColor} />
          <Link
            onClick={logout}
            ml={4}
            color={textColor}
            _hover={{ color: hoverBgColor }}
          >
            Logout
          </Link>
        </Flex>
      </Flex>
    </VStack>
  );

  return (
    <>
      {showHamburger ? (
        <>
          <IconButton
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            onClick={onToggle}
            position="fixed"
            top={8}
            left={5}
            zIndex={10}
            color={iconColor}
            bg={bgColor}
          />
          <Box
            pos="fixed"
            top="0"
            left="0"
            h="full"
            w="full"
            bg={bgColor}
            color={textColor}
            overflowY="auto"
            display={isOpen ? 'block' : 'none'}
          >
            {navContent}
          </Box>
        </>
      ) : (
        <Box
          as="nav"
          pos="fixed"
          top="0"
          left="0"
          h="full"
          w={navWidth}
          bg={bgColor}
          color={textColor}
          display={showContent ? 'block' : 'none'}
        >
          {navContent}
        </Box>
      )}
    </>
  );
};

export default Nav;
