import React, { useEffect } from 'react';
import {
  Box,
  // useBreakpointValue,
} from '@chakra-ui/react';
import Hero from '../Hero/Hero';
// import EntryCard from '../EntryCard/EntryCard';
// import { useEntryType } from '../../hooks/useEntryType';
import CreditBanner from '../CreditBanner/CreditBanner';
import HeaderImage from '../../assets/header.jpg';
import { useUserContext } from '../../providers/UserProvider';

const Dashboard = () => {
  const { user, projects, loading, subscription } = useUserContext();
  // const [normalizedProjects, setNormalizedProjects] = useState([]);
  // const [scripts, setScripts] = useState([]);
  // const [voiceovers, setVoiceovers] = useState([]);

  // const dummyVoiceovers = [
  //   {
  //     VoiceoverName: 'Introduction to Window Tinting',
  //     Duration: '05:20',
  //     Artist: 'John Doe',
  //     Format: 'MP3',
  //     Usage: 'Commercial',
  //   },
  //   {
  //     VoiceoverName: 'Introduction to Window Tinting',
  //     Duration: '05:20',
  //     Artist: 'John Doe',
  //     Format: 'MP3',
  //     Usage: 'Commercial',
  //   },
  //   {
  //     VoiceoverName: 'Introduction to Window Tinting',
  //     Duration: '05:20',
  //     Artist: 'John Doe',
  //     Format: 'MP3',
  //     Usage: 'Commercial',
  //   },
  // ];

  // const [selectedType, handleTypeChange] = useEntryType('My Projects');

  // let dataToRender;
  // switch (selectedType) {
  //   case 'My Projects':
  //     dataToRender = normalizedProjects;
  //     break;
  //   case 'My Scripts':
  //     dataToRender = scripts;
  //     break;
  //   case 'My Voiceovers':
  //     dataToRender = dummyVoiceovers;
  //     break;
  //   default:
  //     dataToRender = [];
  // }

  useEffect(() => {
    if (projects) {   
      console.log('Projects:', projects);
      console.log('User:', user);
      console.log('Subscription:', subscription);
      // Normalizing scripts data from each project
      // const allScripts = projects.reduce((acc, project) => {
      //   // This assumes 'scripts' is an array of script objects with a 'title' key
      //   const projectScripts = project.scripts.map(script => ({
      //     Title: script.title, // or any other key that holds the script's title
      //     WordCount: script.wordCount, // Replace with actual logic to calculate word count
      //     Duration: script.duration, // Replace with actual logic to determine duration
      //     Project: project.title, // Assuming 'title' is the project title
      //   }));
      //   return [...acc, ...projectScripts];
      // }, []);

      // setScripts(allScripts);

      // Normalizing project data
      // const newNormalizedProjects = projects.map(project => ({
      //   ProjectName: project.title,
      //   StartDate: project.startDate || 'Unknown',
      //   EndDate: project.endDate || 'Unknown',
      //   Status: project.status || 'Not specified',
      // }));

      // setNormalizedProjects(newNormalizedProjects);
    }
  }, [projects, subscription, user]);

  // const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
  

  return (
    <Box mt={6} mb={20}>

      <Hero showContent bg={HeaderImage} />

      <CreditBanner user={user} subscription={subscription} loading={loading} />

      {/* <HStack
        justify={flexDirection === 'column' ? 'center' : 'space-between'}
        w={'100%'}
        mb={8}
        flexDirection={flexDirection}
      >
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            {selectedType}
          </MenuButton>
          <MenuList zIndex={9999} bg={'gunmetal.500'}>
            <MenuItem
              bg={'gunmetal.500'}
              _hover={{ bg: 'gunmetal.400' }}
              onClick={() => handleTypeChange('My Projects')}
            >
              My Projects
            </MenuItem>
            <MenuDivider />
            <MenuItem
              bg={'gunmetal.500'}
              _hover={{ bg: 'gunmetal.400' }}
              onClick={() => handleTypeChange('My Scripts')}
            >
              My Scripts
            </MenuItem>
            <MenuDivider />
            <MenuItem
              bg={'gunmetal.500'}
              _hover={{ bg: 'gunmetal.400' }}
              onClick={() => handleTypeChange('My Voiceovers')}
            >
              My Voiceovers
            </MenuItem>
          </MenuList>
        </Menu>
        <Button
          size="sm"
          rounded={'full'}
          bg={'transparent'}
          border={'1px solid white'}
          _hover={{
            bg: 'beige.300',
            border: '1px solid',
            borderColor: 'beige.300',
          }}
          mt={flexDirection === 'column' ? 4 : 0} // Add margin top in column layout
        >
          See All
        </Button>
      </HStack> */}

      {/* <VStack spacing={4} align="stretch">
        {Array.isArray(dataToRender) &&
          dataToRender.map((entry, index) => (
            <EntryCard key={index} data={entry} selectedType={selectedType} />
          ))}
      </VStack> */}

    </Box>
  );
};

export default Dashboard;
