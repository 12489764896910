import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  VStack,
  useDisclosure,
  Tabs,
  Tab,
  Text,
  Icon,
  Radio,
  RadioGroup,
  TabList,
  TabPanels,
  TabPanel,
  SimpleGrid,
} from '@chakra-ui/react';
import { IoSettingsSharp, IoColorPaletteSharp } from 'react-icons/io5';

const FlyoutMenu = ({ isOpen, onClose }) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
      <DrawerOverlay />
      <DrawerContent bg="#2F4957">
        <DrawerBody p={0}>
          <Tabs>
            <TabList
              bg="#213A47"
              pt={10}
              mb={0}
              pb={0}
            >
              <Tab display="flex" alignItems="center" gap="2">
                <Icon as={IoSettingsSharp} color="white" fontSize="20px" />
                <Text color="white">Settings</Text>
              </Tab>
              <Tab display="flex" alignItems="center" gap="2">
                <Icon as={IoColorPaletteSharp} color="white" fontSize="20px" />
                <Text color="white">Look and Feel</Text>
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <VStack align="flex-start" spacing={2} mb={10}>
                    <Text>Voice Actor Selected</Text>
                    <Text>Claire</Text>
                    <Button>Change</Button>     
                </VStack>

                <VStack align="flex-start" spacing={2}>
                <Text>Tone</Text>
                <RadioGroup>
                <SimpleGrid columns={2} spacing={20}>
                    <VStack spacing={2}>
                        <Radio value="1">Tone 1</Radio>
                        <Radio value="2">Tone 2</Radio>
                        <Radio value="3">Tone 3</Radio>
                    </VStack>

                    <VStack spacing={2}>
                        <Radio value="4">Tone 1</Radio>
                        <Radio value="5">Tone 2</Radio>
                        <Radio value="6">Tone 3</Radio>
                    </VStack>
                </SimpleGrid>
                </RadioGroup>
                </VStack>

              </TabPanel>
              <TabPanel>
                <Text>Look and Feel</Text>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerBody>
        <DrawerFooter>
          <Button onClick={onClose}>Close</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default FlyoutMenu;
