import React from 'react';
import { useUserContext } from '../../providers/UserProvider';
import { useMediaQuery } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { Flex, Container, Spacer } from '@chakra-ui/react';
import { INTERCOM_APP_ID, pageTransition, pageVariants } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import Nav from '../Nav/Nav';
import CreditsInfo from '../CreditsInfo/CreditsInfo';
import UserInfo from '../UserInfo/UserInfo';
import UtilityButtons from '../UtilityButtons/UtilityButtons';
import Intercom from '@intercom/messenger-js-sdk';

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const [isSmallScreen] = useMediaQuery('(max-width: 767px)');
  const { subscription, user, loading, logout, isAuthenticated } = useUserContext();

  console.log("user", user);

  const goToHelp = () => {
    navigate('/help');
  };

  const goToSettings = () => {
    navigate('/settings');
  };

  Intercom(
    {
      app_id: INTERCOM_APP_ID,
      user_id: user?.auth0Id,
      name: user?.name,
      email: user?.email,
      created_at: user?.createdAt,
    }
  )

  return (
    <>
      <Flex direction={{ base: 'column', md: 'row' }}>
        {isAuthenticated && !isSmallScreen && <Nav />}
        <Flex direction="column" flex="1" pl={{ base: '0', md: '64' }}>
          <Flex
            w="100%"
            p={4}
            align="center"
            gap={4}
            direction={{ base: 'column', md: 'row' }}
          >
            <CreditsInfo
              user={user}
              subscription={subscription}
              loading={loading}
            />
            <Spacer />
            <UserInfo user={user} loading={loading} />
            {/* <Spacer /> */}
            <UtilityButtons
              logout={logout}
              loading={loading}
              goToHelp={goToHelp}
              goToSettings={goToSettings}
              userId={user?._id}
            />
          </Flex>

          <Container maxW="container.lg" p={6} overflowX="hidden">
            <AnimatePresence mode="wait">
              <motion.div
                key={window.location.pathname}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
              >
                {children}
              </motion.div>
            </AnimatePresence>
          </Container>
        </Flex>
      </Flex>
    </>
  );
};

export default Layout;
