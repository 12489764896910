import { useEffect, useState, useRef } from 'react';

const useAudioPlayer = (audioUrl) => {
  const [audioElement, setAudioElement] = useState(null);
  const [analyser, setAnalyser] = useState(null);
  const [frequencyData, setFrequencyData] = useState(new Uint8Array(256)); 
  const [fullWaveformData, setFullWaveformData] = useState(new Uint8Array(256)); // Full waveform data
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(30);
  const audioContextRef = useRef(null);
  const rafId = useRef(null);

  const initializeAudioContext = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
  };

  useEffect(() => {
    if (!audioUrl) return;
    
    initializeAudioContext();
    
    const context = audioContextRef.current;
    if (context) {
      try {
        const element = new Audio();
        element.crossOrigin = 'anonymous';
        
        // Load the audio file using fetch first
        fetch(audioUrl)
          .then(response => response.blob())
          .then(blob => {
            const objectURL = URL.createObjectURL(blob);
            element.src = objectURL;
            
            const source = context.createMediaElementSource(element);
            const analyserNode = context.createAnalyser();
            
            analyserNode.fftSize = 2048;
            source.connect(analyserNode);
            analyserNode.connect(context.destination);
            
            setAudioElement(element);
            setAnalyser(analyserNode);
            
            // Capture full waveform data when the audio is loaded
            element.addEventListener('loadedmetadata', () => {
              setDuration(element.duration);
              
              // Create a larger array to capture the full waveform
              const fullDataArray = new Uint8Array(analyserNode.frequencyBinCount);
              analyserNode.getByteTimeDomainData(fullDataArray);
              setFullWaveformData(fullDataArray); // Store the full waveform data
            });
            
            element.addEventListener('timeupdate', () => {
              setCurrentTime(element.currentTime);
            });

            return () => {
              element.pause();
              element.src = ''; // Stop audio and release resources
              URL.revokeObjectURL(element.src);
              if (source) source.disconnect();
              if (analyserNode) analyserNode.disconnect();
              if (context.state !== 'closed') {
                context.close();
              }
            };
          })
          .catch(error => {
            console.error("Error fetching audio:", error);
            setAudioElement(null);
          });

      } catch (error) {
        console.error("Error initializing audio element:", error);
        setAudioElement(null);
      }
    }
  }, [audioUrl]);

  useEffect(() => {
    if (analyser) {
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      const updateFrequencyData = () => {
        analyser.getByteFrequencyData(dataArray);
        setFrequencyData([...dataArray.slice(0, 256)]); 
        rafId.current = requestAnimationFrame(updateFrequencyData);
      };
      updateFrequencyData();

      return () => cancelAnimationFrame(rafId.current);
    }
  }, [analyser]);

  const playPause = () => {
    if (!audioElement) {
      return;
    }
  
    if (audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume();
    }
  
    isPlaying ? audioElement.pause() : audioElement.play();
    setIsPlaying(prevState => !prevState);
  };

  const changeVolume = (value) => {
    if (audioElement) {
      audioElement.volume = value / 100;
      setVolume(value);
    }
  };

  const seek = (value) => {
    if (audioElement) {
      audioElement.currentTime = value;
      setCurrentTime(value);
    }
  };

  return {
    isPlaying,
    currentTime,
    duration,
    volume,
    frequencyData,
    fullWaveformData,
    playPause,
    changeVolume,
    seek,
    initializeAudioContext,
  };
};

export default useAudioPlayer;