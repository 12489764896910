import React, { createContext, useContext, useState } from 'react';

const FormContext = createContext();

export const useSignupFormContext = () => useContext(FormContext);

const initialContentTypes = [
  { label: 'Tiktok or Instagram Videos', isChecked: false },
  { label: 'Podcasts', isChecked: false },
  { label: 'YouTube Videos', isChecked: false },
  { label: 'Commercials', isChecked: false },
  { label: 'Product Ads', isChecked: false },
];

export const SignupFormProvider = ({ children }) => {
  const [contentTypes, setContentTypes] = useState(initialContentTypes);
  const [skillLevel, setSkillLevel] = useState('');
  const [marketingOptIn, setMarketingOptIn] = useState(true);
  const [enhanceApp, setEnhanceApp] = useState(true);

  const value = {
    contentTypes,
    setContentTypes,
    skillLevel,
    setSkillLevel,
    marketingOptIn,
    setMarketingOptIn,
    enhanceApp,
    setEnhanceApp,
  };

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};
