import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Fade,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Flex,
  SimpleGrid,
  TabIndicator,
  Divider,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteList,
  AutoCompleteItem,
} from "@choc-ui/chakra-autocomplete";
import { useUserContext } from '../providers/UserProvider';
import SubscriptionPlan from '../components/SubscriptionPlan/SubscriptionPlan';
import UserReferrals from '../components/UserReferrals/UserReferrals';
import ReferralCode from '../components/ReferralCode/ReferralCode';
import useUpdateUser from '../hooks/useUpdateUser';
import useReferral from '../hooks/useReferral';
import useDebounce from '../hooks/useDebounce';
import { states } from '../utils/states.utils';
import { countries } from '../utils/countries.utils';
import { CloseIcon } from '@chakra-ui/icons';

const SettingsSection = ({ title, description, children }) => {
  return (
    <Box>
      <Heading size="lg" mt={10} mb={4}>
        {title}
      </Heading>
      <Text mb={8}>{description}</Text>
      {children}
    </Box>
  );
};

const Settings = () => {
  const { user, subscription, loading } = useUserContext();
  const [userState, setUserState] = useState(user);
  const { updateUserData } = useUpdateUser(userState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const referralHook = useReferral();
  const [isOnFreePlan, setIsOnFreePlan] = useState(
    subscription.subscriptionPlan.name.toLowerCase() === 'free'
  );

  const debouncedSetUserState = useDebounce((newState) => {
    setUserState(newState);
    setHasChanges(true);
  }, 300);

  const handleInputChange = (event) => {
    const { name, checked, type, value } = event.target;
    const newValue = type === 'checkbox' ? checked : value;

    const updatedUserState = {
      ...userState,
      [name]: newValue,
    };

    setUserState(updatedUserState);
    debouncedSetUserState(updatedUserState);
  };

  const handleTabClick = (index) => {
    if (hasChanges) {
      onOpen();
    } else {
      setSelectedTabIndex(index);
    }
  };

  const handleSaveAndProceed = async () => {
    await handleSubmit();
    setSelectedTabIndex(selectedTabIndex);
    onClose();
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    await updateUserData(userState);
    setHasChanges(false);
  };

  const [stateInputValue, setStateInputValue] = useState(userState.state || '');
  const [countryInputValue, setCountryInputValue] = useState(userState.country || '');

  useEffect(() => {
    setStateInputValue(userState.state || '');
    setCountryInputValue(userState.country || '');
  }, [userState.state, userState.country]);

  const handleStateChange = (value) => {
    setStateInputValue(value);
  };

  const handleCountryChange = (value) => {
    setCountryInputValue(value);
  };

  const handleStateSelect = (selectedItem) => {
    const selectedState = selectedItem.item.value;
    setStateInputValue(selectedState);
    
    const updatedUserState = {
      ...userState,
      state: selectedState,
    };

    setUserState(updatedUserState);
    debouncedSetUserState(updatedUserState);
  };

  const handleCountrySelect = (selectedItem) => {
    const selectedCountry = selectedItem.item.value;
    setCountryInputValue(selectedCountry);
    
    const updatedUserState = {
      ...userState,
      country: selectedCountry,
    };

    setUserState(updatedUserState);
    debouncedSetUserState(updatedUserState);
  };

  if (loading) {
    return (
      <Flex h={'100vh'} alignItems={'center'} justifyContent={'center'}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Heading mb="5">Settings</Heading>
      <Tabs
        variant="unstyled"
        isLazy
        index={selectedTabIndex}
        aria-label="Settings Tabs"
      >
        <TabList>
          {['Profile', 'Billing', 'Privacy', 'Referrals'].map((tab, index) => (
            <Tab
              key={tab}
              _selected={{ color: 'moonstone.400', fontWeight: 'bold' }}
              onClick={() => handleTabClick(index)}
            >
              {tab}
            </Tab>
          ))}
        </TabList>

        <Divider />

        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="moonstone.400"
          borderRadius="1px"
        />
        <TabPanels>
          {/* Profile Settings */}
          <TabPanel>
            <Fade in>
              <SettingsSection
                title="Profile Settings"
                description="Manage your profile information here."
              >
                <SimpleGrid
                  columns={{ base: '1', md: '2' }}
                  spacing={10}
                  mb={10}
                >
                  <Box>
                    <FormControl py={2}>
                      <FormLabel>User ID</FormLabel>
                      <Input
                        placeholder={userState._id}
                        readOnly
                        key={userState._id}
                      />
                    </FormControl>
                    <FormControl py={2}>
                      <FormLabel>Stripe Customer ID</FormLabel>
                      <Input
                        placeholder={userState.stripeCustomerId}
                        readOnly
                      />
                    </FormControl>

                    <FormControl py={2}>
                      <FormLabel>Username</FormLabel>
                      <Input
                        key={userState.auth0Id}
                        readOnly
                        name="auth0Id"
                        placeholder={userState.auth0Id || ''}
                      />
                    </FormControl>

                    <FormControl py={2}>
                      <FormLabel>Email Address</FormLabel>
                      <Input
                        value={userState.email || ''}
                        onChange={handleInputChange}
                        name="email"
                        placeholder="Enter your email..."
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    {/* Address1 */}
                    <FormControl py={2}>
                      <FormLabel>Address 1</FormLabel>
                      <Input
                        value={userState.address1 || ''}
                        onChange={handleInputChange}
                        name="address1"
                        placeholder="Enter your address..."
                      />
                    </FormControl>

                    {/* Address2 */}
                    <FormControl py={2}>
                      <FormLabel>Address 2</FormLabel>
                      <Input
                        value={userState.address2 || ''}
                        onChange={handleInputChange}
                        name="address2"
                        placeholder="Enter your address..."
                      />
                    </FormControl>

                    {/* City */}
                    <FormControl py={2}>
                      <FormLabel>City</FormLabel>
                      <Input
                        value={userState.city || ''}
                        onChange={handleInputChange}
                        name="city"
                        placeholder="Enter your city..."
                      />
                    </FormControl>
                    
                    {/* State */}
                    <FormControl py={2}>
                      <FormLabel>State</FormLabel>
                      <AutoComplete
                        openOnFocus
                        onSelectOption={handleStateSelect}
                      >
                        <AutoCompleteInput
                          value={stateInputValue}
                          onChange={(e) => handleStateChange(e.target.value)}
                          placeholder="Select your state..."
                        />
                        <AutoCompleteList>
                          {states.map((state, index) => (
                            <AutoCompleteItem
                              key={`option-${index}`}
                              value={state}
                              textTransform="capitalize"
                            >
                              {state}
                            </AutoCompleteItem>
                          ))}
                        </AutoCompleteList>
                      </AutoComplete>
                      {stateInputValue && (
                        <IconButton
                          size="sm"
                          icon={<CloseIcon />}
                          onClick={() => {
                            setStateInputValue('');
                            setUserState({...userState, state: ''});
                            debouncedSetUserState({...userState, state: ''});
                          }}
                          aria-label="Clear selection"
                          position="absolute"
                          right="2"
                          top="50%"
                          transform="translateY(-50%)"
                        />
                      )}
                    </FormControl>

                    {/* Zip Code */}
                    <FormControl py={2}>
                      <FormLabel>Zip Code</FormLabel>
                      <Input
                        value={userState.zipCode || ''}
                        onChange={handleInputChange}
                        name="zipCode"
                        placeholder="Enter your zip code..."
                      />
                    </FormControl>

                    {/* Country */}
                    <FormControl py={2}>
                      <FormLabel>Country</FormLabel>
                      <AutoComplete
                        openOnFocus
                        onSelectOption={handleCountrySelect}
                      >
                        <AutoCompleteInput
                          value={countryInputValue}
                          onChange={(e) => handleCountryChange(e.target.value)}
                          placeholder="Select your country..."
                        />
                        <AutoCompleteList>
                          {countries.map((country, index) => (
                            <AutoCompleteItem
                              key={`option-${index}`}
                              value={country}
                              textTransform="capitalize"
                            >
                              {country}
                            </AutoCompleteItem>
                          ))}
                        </AutoCompleteList>
                      </AutoComplete>
                      {countryInputValue && (
                        <IconButton
                          size="sm"
                          icon={<CloseIcon />}
                          onClick={() => {
                            setCountryInputValue('');
                            setUserState({...userState, country: ''});
                            debouncedSetUserState({...userState, country: ''});
                          }}
                          aria-label="Clear selection"
                          position="absolute"
                          right="2"
                          top="50%"
                          transform="translateY(-50%)"
                        />
                      )}
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </SettingsSection>
            </Fade>
          </TabPanel>

          {/* Billing Settings */}
          <TabPanel>
            <Fade in>
              <SettingsSection
                title="Billing Settings"
                description="Update your billing information here."
              >
                <SubscriptionPlan
                  user={userState}
                  subscription={subscription}
                />
              </SettingsSection>
            </Fade>
          </TabPanel>

          {/* Privacy Settings */}
          <TabPanel>
            <Fade in>
              <SettingsSection
                title="Privacy Settings"
                description="Change your privacy settings here."
              >
                <SimpleGrid
                  columns={{ base: '1', md: '2' }}
                  spacing={10}
                  mb={10}
                >
                  <FormControl display="flex" alignItems="center" py={2}>
                    <Tooltip
                      label="Receive emails about upcoming features, discounts and tricks."
                      aria-label="Tooltip"
                      closeOnClick={true}
                    >
                      <FormLabel mb="0">Receive Marketing Emails</FormLabel>
                    </Tooltip>
                    <Switch
                      size="lg"
                      name="marketingOptIn"
                      isChecked={userState.marketingOptIn}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl display="flex" alignItems="center" py={2}>
                    <Tooltip
                      label="Paid users can opt out of data collection."
                      aria-label="Tooltip"
                      isDisabled={!isOnFreePlan}
                      closeOnClick={true}
                    >
                      <FormLabel mb="0">Anonymous Data Collection</FormLabel>
                    </Tooltip>

                    <Switch
                      size="lg"
                      name="dataOptIn"
                      isDisabled={isOnFreePlan}
                      isChecked={userState.dataOptIn || isOnFreePlan}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </SimpleGrid>
              </SettingsSection>
            </Fade>
          </TabPanel>

          {/* Referral Settings */}
          <TabPanel>
            <Fade in>
              <SettingsSection
                title="Referral Settings"
                description="Manage your referral codes and referred users here."
              >
                <ReferralCode referralHook={referralHook} />
                <UserReferrals referralHook={referralHook} />
              </SettingsSection>
            </Fade>
          </TabPanel>
        </TabPanels>
      </Tabs>

      {loading ? (
        <Button
          type="submit"
          isLoading
          loadingText={'Submitting'}
          mt={4}
          _hover={{ bg: 'gunmetal.400' }}
        >
          Save Changes
        </Button>
      ) : (
        <Button
          type="submit"
          isDisabled={!hasChanges}
          mt={4}
          ml={4}
          _hover={{ bg: 'gunmetal.400' }}
        >
          Save Changes
        </Button>
      )}

      {/* Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Unsaved Changes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            You have unsaved changes. Would you like to save them before
            switching tabs?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveAndProceed}>
              Save and Proceed
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Settings;
