import React from 'react';
import { Box, Button, Flex, Text, SimpleGrid } from '@chakra-ui/react';

const PurchaseCredits = ({ creditOptions, onPurchase, isLoading }) => {
  return (
    <Box>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 4 }} spacing={5}>
        {creditOptions.map((option, index) => (
          <Flex
            key={index}
            direction="column"
            alignItems="center"
            justifyContent="center"
            p={5}
            bg="gray.400"
            rounded="md"
            boxShadow="base"
            _hover={{ bg: 'beige.300' }}
            color={'white'}
          >
            <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold">
              {option.credits} Credits
            </Text>
            <Text fontSize={{ base: 'sm', md: 'md' }}>{option.price}</Text>
            <Button
              mt={3}
              colorScheme="blue"
              onClick={() => onPurchase(option.credits)}
              isLoading={isLoading}
            >
              Purchase
            </Button>
          </Flex>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default PurchaseCredits;
