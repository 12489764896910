import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Spinner } from '@chakra-ui/react';
import { useUserContext } from './providers/UserProvider';

const ProtectedRoute = ({ children }) => {
  const { user, loading, isAuthenticated } = useUserContext();
  const navigate = useNavigate();

  console.log("Is Authenticated", isAuthenticated);
  
  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/login');
    }

    // Re-Enable this later when I adjust profile setup
    // if (isAuthenticated && (!user || user?.subscriptionStatus !== 'active')) {
    //   navigate('/profile-setup'); 
    // }
  }, [isAuthenticated, loading, user, navigate]);

  if (loading) {
    return (
      <Flex minHeight="100vh" align="center" justify="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return children;
};

export default ProtectedRoute;
