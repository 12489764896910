import React, { useState, useEffect } from 'react';
import {
  Heading,
  Text,
  Stack,
  Flex,
  Button,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import GenerativeProgress from '../GenerativeProgress/GenerativeProgress';
import { useAdGeneration } from '../../providers/AdGenerationProvider';
import useGenerateScript from '../../hooks/useGenerateScript';
import useSaveScript from '../../hooks/useSaveScript';

const ScriptGenerating = () => {
  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });

  const navigate = useNavigate();
  const { adType, projectDetails, scriptDetails, setScriptDetails, scriptIdea, voiceActor } = useAdGeneration();
  const { generateScriptData, hookLoading: generateLoading } = useGenerateScript();
  const { saveScriptData, hookLoading: saveLoading } = useSaveScript();
  
  const {
    brandName,
    productName,
    callToAction,
    duration,
    industry,
    targetAudience,
  } = scriptDetails;

  const [loading, setLoading] = useState(false);
  const [script, setScript] = useState(null);

  const handleGenerateScript = async () => {
    setLoading(true);
    try {
      const data = {
        adType,
        project: projectDetails.project.id,
        brandName,
        productName,
        callToAction,
        duration,
        industry,
        targetAudience,
        scriptIdea,
      }

      console.log('data in ScriptGenerating component', data);

      const generatedScript = await generateScriptData(data);

      setScript(generatedScript);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const data = {
        title: scriptIdea.title,
        script: script.script,
        projectId: projectDetails.project.id,
        brandName,
        productName,
        callToAction,
        duration,
        industry,
        targetAudience,
        adType,
        voiceActor
      };

      const savedScript = await saveScriptData(data);
      // Append the script to the existing scriptDetails
      console.log('savedScript', savedScript)
      setScriptDetails(prevDetails => ({
        ...prevDetails,
        script: savedScript.script
      }));
      navigate(`/script-editor/${savedScript._id}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('scriptDetails', scriptDetails);
    console.log('projectDetails', projectDetails);
    console.log('scriptIdea', scriptIdea);
    console.log('voiceActor', voiceActor);
  }, [scriptDetails, projectDetails, scriptIdea, voiceActor]);

  return (
    <Flex
      direction={'column'}
      justify={'center'}
      align={'center'}
      w={'100%'}
      bgGradient="linear(to-b, #557D94, #34505F)"
      h="85vh"
      borderRadius={{ base: 0, md: '2xl' }}
      mb={8}
    >
      <Stack
        direction={'column'}
        spacing={10}
        w={'100%'}
        p={{ base: 6, md: 20 }}
        align={'center'}
        justify={'stretch'}
      >
        <VStack>
          <Heading as={'h2'} size={headingSize} mb={2}>
            "{scriptIdea.title}"
          </Heading>

          <Text textAlign={'center'} maxW={'450px'} mb={8}>
            Click below to generate your script. Make any adjustments to fit
            your brand, voice, or to fill in any gaps.
          </Text>

          <Button
            p={8}
            size={'lg'}
            mb={2}
            onClick={script ? handleSave : handleGenerateScript}
            isLoading={loading || generateLoading || saveLoading}
            loadingText="Generating..."
            _hover={{ bg: 'gunmetal.400' }}
          >
            {script ? 'Go To Script Editor' : 'Generate Script'}
          </Button>

          <Button
            onClick={() => navigate('/generate-ad')}
            variant="link"
            color={'gray.300'}
            isLoading={loading || generateLoading || saveLoading}
          >
            Back to Start
          </Button>
        </VStack>

        {loading && <GenerativeProgress />}
      </Stack>
    </Flex>
  );
};

export default ScriptGenerating;
