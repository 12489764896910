import React from 'react';
import {
  Avatar,
  AvatarBadge,
  Spinner,
  useBreakpointValue,
  Flex,
  Badge,
  VStack,
  Tooltip,
} from '@chakra-ui/react';
import { AiOutlineUser } from 'react-icons/ai';

const UserInfo = ({ user, loading }) => {
  const avatarSize = useBreakpointValue({ base: 'md', md: 'md' });
  const fontSize = useBreakpointValue({ base: '1rem', md: '1.5rem' });

  if (loading) {
    return <Spinner />;
  }

  const avatarImg = user && user?.picture ? user?.picture : undefined;
  const avatarName = user && user?.email ? user?.email : 'User';

  return (
    <Flex alignItems="center" flex={2} justifyContent={'flex-end'} mr={8}>

      <Tooltip label={avatarName || 'User'} aria-label="User">
        <Avatar
          name={avatarName || 'User'}
          src={avatarImg}
          size={avatarSize}
          bg={!avatarImg ? 'moonstone.500' : undefined}
          icon={!avatarImg ? <AiOutlineUser fontSize={fontSize} /> : undefined}
          mr={4}
          border={'2px solid #375566'}
        >
          <AvatarBadge
            boxSize="1em"
            bg={
              user && user.subscriptionStatus === 'active'
                ? 'green.500'
                : 'red.500'
            }
          />
        </Avatar>
      </Tooltip>

      <Flex>
        {user ? (
          <VStack spacing={1} justify={'center'} align="stretch">
            <Tooltip
              label="Gain Levels to unlock free credits and new achievements."
              aria-label="Experience Level"
            >
              <Badge colorScheme="green">Level {user?.level}</Badge>
            </Tooltip>

            <Badge colorScheme="blue">{user?.xp} XP</Badge>
          </VStack>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default UserInfo;
