import React, { useState } from 'react';
import { IconButton, Spinner, Tooltip, useBreakpointValue, HStack, VStack, Flex, Menu, MenuButton, MenuList, MenuItem, Box, Text, Button } from '@chakra-ui/react';
import { FaSignOutAlt, FaCog, FaQuestionCircle, FaBell } from 'react-icons/fa';
import Notifications from '../Notifications/Notifications';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import { useColorModeValue } from '@chakra-ui/react';

const UtilityButtons = ({ logout, loading, goToSettings, goToHelp, userId }) => {
  const bgColor = useColorModeValue('beige.400', 'gunmetal.500');
  const hoverBgColor = useColorModeValue('beige.300', 'gunmetal.400');
  const textColor = useColorModeValue('beige.100', 'gunmetal.100');
  const menuListBgColor = useColorModeValue('beige.500', 'gunmetal.700');
  const menuItemBgColor = useColorModeValue('beige.400', 'gunmetal.600');
  const menuItemTextColor = useColorModeValue('beige.100', 'gunmetal.100');
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const layout = useBreakpointValue({ base: 'vertical', md: 'horizontal' });

  const [showNotifications, setShowNotifications] = useState(false);

  if (loading) {
    return <Spinner />;
  }

  const ButtonGroup = layout === 'vertical' ? VStack : HStack;

  return (
    <Flex alignItems="center" flex={1}>
      <ButtonGroup spacing={2}>
        <Tooltip label="Help" aria-label="Help">
          <IconButton
            icon={<FaQuestionCircle />}
            isRound
            onClick={goToHelp}
            size={buttonSize}
            variant="filled"
            bg={bgColor}
            color={textColor}
            _hover={{ bg: hoverBgColor }}
          />
        </Tooltip>

        <Tooltip label="Settings" aria-label="Settings">
          <IconButton
            icon={<FaCog />}
            isRound
            onClick={goToSettings}
            size={buttonSize}
            variant="filled"
            bg={bgColor}
            _hover={{ bg: hoverBgColor }}
            color={textColor}
          />
        </Tooltip>
        <Menu>
          <Tooltip label="Notifications" aria-label="Notifications">
            <MenuButton
              as={IconButton}
              icon={<FaBell />}
              isRound
              size={buttonSize}
              variant="filled"
              bg={bgColor}
            _hover={{ bg: hoverBgColor }}
            color={textColor}
            />
          </Tooltip>
          <MenuList bg={menuListBgColor}>
            <Box maxH="400px" overflowY="auto" px={4} py={2} bg={menuListBgColor}>
              <Notifications userId={userId} menuItemBgColor={menuItemBgColor} menuItemTextColor={menuItemTextColor} />
            </Box>
          </MenuList>
        </Menu>

          <ColorModeSwitcher size={buttonSize} />

        <Tooltip label="Logout" aria-label="Logout">
          <IconButton
            icon={<FaSignOutAlt />}
            isRound
            onClick={logout}
            size={buttonSize}
            variant="filled"
            bg={bgColor}
            _hover={{ bg: hoverBgColor }}
            color={textColor}
          />
        </Tooltip>
      </ButtonGroup>
    </Flex>
  );
};

export default UtilityButtons;
