import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { SignupFormProvider } from './providers/SignupFormProvider';
import Login from './pages/Login';
import Layout from './components/Layout/Layout';
import Project from './pages/Project';
import Projects from './pages/Projects';
import Scripts from './pages/Scripts';
import Settings from './pages/Settings';
import Help from './pages/Help';
import ProfileSetup from './pages/ProfileSetup';
import Trivia from './pages/Trivia';
import Dashboard from './components/Dashboard/Dashboard';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';
import SubscriptionConfirmation from './pages/SubscriptionConfirmation';
import ProtectedRoute from './ProtectedRoute';
import GenerateAd from './pages/GenerateAd';
import ScriptProjectDetails from './components/ScriptProjectDetails/ScriptProjectDetails';
import ScriptEditor from './components/ScriptEditor/ScriptEditor';
import AudioSelectAVoice from './components/AudioSelectAVoice/AudioSelectAVoice';
import FullScreenLayout from './components/FullScreenLayout/FullScreenLayout';
import ScriptDetails from './components/ScriptDetails/ScriptDetails';
import ScriptGenerating from './components/ScriptGenerating/ScriptGenerating';
import Callback from './pages/Callback';

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout>
              <Dashboard />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route path="/callback" element={<Callback />} />
      <Route
        path="/generate-ad"
        element={
          <ProtectedRoute>
            <Layout>
              <GenerateAd />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/project-details"
        element={
          <ProtectedRoute>
            <Layout>
              <ScriptProjectDetails />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/script-details'
        element={
          <ProtectedRoute>
            <Layout>
              <ScriptDetails />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/projects/:projectId/edit"
        element={
          <ProtectedRoute>
            <Layout>
              <Project />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/script/:scriptId/edit"
        element={
          <ProtectedRoute>
            <FullScreenLayout>
              <ScriptEditor />
            </FullScreenLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/script-editor/:scriptId"
        element={
          <ProtectedRoute>
            <FullScreenLayout>
              <ScriptEditor />
            </FullScreenLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/select-voice"
        element={
          <ProtectedRoute>
            <Layout>
              <AudioSelectAVoice />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/script-generating"
        element={
          <ProtectedRoute>
            <FullScreenLayout>
              <ScriptGenerating />
            </FullScreenLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/projects"
        element={
          <ProtectedRoute>
            <Layout>
              <Projects />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/scripts"
        element={
          <ProtectedRoute>
            <Layout>
              <Scripts />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Layout>
              <Settings />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/help"
        element={
          <ProtectedRoute>
            <Layout>
              <Help />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route path="/profile-setup" element={<ProfileSetup />} />

      <Route
        path="/subscription-confirmation"
        element={
          <SignupFormProvider>
            <ProtectedRoute>
              <SubscriptionConfirmation />
            </ProtectedRoute>
          </SignupFormProvider>
        }
      />

      <Route
        path="/trivia"
        element={
          <ProtectedRoute>
            <Trivia />
          </ProtectedRoute>
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <ProtectedRoute>
            <Layout>
              <PrivacyPolicy />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/terms-of-use"
        element={
          <ProtectedRoute>
            <Layout>
              <TermsOfUse />
            </Layout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default App;
