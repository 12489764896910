import React from 'react';
import {
  Button,
  VStack,
  Text,
  Heading,
  Flex,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize'

const MotionVStack = motion(VStack);

const TriviaEnd = ({ totalScore, totalXp, totalQuestions, correctAnswers, onReturn }) => {
  const confettiPieces = 200;

  const { width, height } = useWindowSize()


  return (
    <Flex direction="column" justify="center" align="stretch" h="100vh" bg={'gunmetal.600'}>
      <Confetti numberOfPieces={confettiPieces} width={width} height={height} />
      <MotionVStack
        spacing={6}
        align="center"
        initial={{ scale: 0.5, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Heading size="2xl" color="white">
          Congratulations!
        </Heading>
        <Text color="white" fontSize="xl">
          You have completed the trivia.
        </Text>
        <Text color="white" fontSize="lg">
          Your Final Score: {totalScore} XP
        </Text>
        <Text color="white" fontSize="lg">
          Correct Answers: {correctAnswers} / {totalQuestions}
        </Text>
        <Button colorScheme="teal" onClick={onReturn}>
          Return to Script Editor
        </Button>
      </MotionVStack>
    </Flex>
  );
};

export default TriviaEnd;
