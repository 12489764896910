import { useCallback, useState } from 'react';
import { useUserContext } from '../providers/UserProvider';
import { useToast } from '@chakra-ui/react';
import { processStripeSuccess } from '../services/billing.service';

const useStripeSuccess = () => {
  const { token } = useUserContext();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleStripeSuccess = useCallback(
    async (sessionId) => {
      setLoading(true);
      try {
        const success = await processStripeSuccess(sessionId, token);

        // Show success toast
        toast({
          title: 'Subscription Successful',
          description: 'Your purchase was successful.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });

        return success;
      } catch (error) {
        console.error('Error processing Stripe success:', error);

        // Show error toast
        toast({
          title: 'Subscription Failed',
          description: 'Your purchase was not successful. Please try again.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

        return false;  // Return a failure indicator
      } finally {
        setLoading(false);
      }
    },
    [toast, token]
  );

  return { handleStripeSuccess, loading };
};

export default useStripeSuccess;
