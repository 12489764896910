import React, { useState, useEffect } from 'react';
import { Heading, Spinner, Container, Flex, Button, Input, Box, FormControl, FormLabel } from '@chakra-ui/react';
import { useUserContext } from '../providers/UserProvider';
import { AnimatePresence } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { pageTransition, pageVariants, MotionFlex } from '../config/config';
import { useAuth0 } from '@auth0/auth0-react';
import { handleAuth0Login, updateUser } from '../services/user.service';
import PricingPage from '../components/PricingPage/PricingPage';
import useGetPlans from '../hooks/useGetPlans';
import { STRIPE_ENABLED } from '../config/config';

const ProfileSetup = () => {
  const { user, loading, setUser, logout, token } = useUserContext();
  const { plans } = useGetPlans();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const referralCodeFromParams = new URLSearchParams(location.search).get('referralCode');
  const [referralCode, setReferralCode] = useState(referralCodeFromParams || '');
  const [isCheckingCode, setIsCheckingCode] = useState(false);
  const [codeError, setCodeError] = useState('');

  useEffect(() => {
    console.log('Plans:', plans);
  }, [plans]);

  useEffect(() => {
    const fetchUser = async () => {
      if (referralCode) {
        try {
          const accessToken = await getAccessTokenSilently();
          const auth0User = await handleAuth0Login({ auth0User: accessToken, token, referralCode });

          // Mark the subscription as active for beta users
          const updatedUser = { ...auth0User, subscriptionStatus: 'active' };
          setUser(updatedUser);

          // // Create a user subscription
          // await createUserSubscription(updatedUser._id, 'beta-plan-id', token);

          // Update backend
          await updateUser(updatedUser._id, updatedUser, token);

          // Navigate to subscription confirmation
          navigate('/subscription-confirmation');
        } catch (error) {
          console.error('Error creating user with referral code', error);
        }
      }
    };

    if (user && user.subscriptionStatus === 'active') {
      navigate('/subscription-confirmation');
    } else if (referralCode) {
      fetchUser();
    }
  }, [user, referralCode, navigate, setUser, token, getAccessTokenSilently]);

  const handleReferralCodeSubmit = async () => {
    setIsCheckingCode(true);
    setCodeError('');

    try {
      const accessToken = await getAccessTokenSilently();
      const auth0User = await handleAuth0Login({ auth0User: accessToken, token, referralCode });

      // Mark the subscription as active for beta users
      const updatedUser = { ...auth0User, subscriptionStatus: 'active' };
      setUser(updatedUser);

      // Create a user subscription
      // await createUserSubscription(updatedUser._id, 'beta-plan-id', token);

      // Update backend
      await updateUser(updatedUser._id, updatedUser, token);

      // Navigate to subscription confirmation
      navigate('/subscription-confirmation');
    } catch (error) {
      console.error('Error with referral code', error);
      setCodeError('Invalid referral code. Please try again.');
    } finally {
      setIsCheckingCode(false);
    }
  };

  if (loading || isCheckingCode) {
    return (
      <Flex justify={'center'} align={'center'}>
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex minHeight="100vh" align="center" justify="center">
      <Container maxW="container.xl" p={4} overflowX={'hidden'}>
        <AnimatePresence mode="wait">
          <MotionFlex
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            flexDir={'column'}
            p={10}
            bg={'gunmetal.550'}
            rounded={'xl'}
            shadow={'2xl'}
            justifyContent={'center'}
          >
            <Heading mb="5" textAlign={'center'}>
              Profile Setup
            </Heading>
            {(!STRIPE_ENABLED || !referralCode) && (
              <Box mb={5}>
                <FormControl>
                  <FormLabel htmlFor="referral-code">Referral Code</FormLabel>
                  <Input
                    id="referral-code"
                    type="text"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                    mb={3}
                  />
                </FormControl>
                {codeError && <Box color="red.500" mb={3}>{codeError}</Box>}
                <Button onClick={handleReferralCodeSubmit} colorScheme="teal">
                  Submit Referral Code
                </Button>
              </Box>
            )}
            {STRIPE_ENABLED && plans.length > 0 && (
              <PricingPage userReference={user?._id} userEmail={user?.email} plans={plans} />
            )}
            <Button
              onClick={() => logout({ returnTo: window.location.origin })}
              colorScheme={'blue'}
              mt={5}
            >
              Logout
            </Button>
          </MotionFlex>
        </AnimatePresence>
      </Container>
    </Flex>
  );
};

export default ProfileSetup;
