// src/services/billing.service.js

import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';
import { config } from '../config/config';

// Purchase credits for a user
export const createCheckoutSession = async (
  planId,
  planInterval,
  productId,
  productQuantity,
  token
) => {
  try {
    console.log('Creating Checkout Session');

    const response = await api.post(
      `/billing/checkout`,
      {
        planId,
        planInterval,
        productId,
        productQuantity,
        returnUrl: config.STRIPE_RETURN_URL,
      },
      {
        customHeaders: { token },
      }
    );

    return handleResponse(response, 'Successfully purchased credits.');
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to purchase credits.');
  }
};

// Get the user's billing portal session
export const getCustomerPortalSession = async token => {
  try {
    const response = await api.post(
      '/billing/customer-portal',
      {
        customHeaders: { token },
      }
    );

    return handleResponse(
      response,
      'Error getting the billing portal session.'
    );
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to get the billing portal session.');
  }
};

// Process the Stripe session success
export const processStripeSuccess = async (sessionId, token) => {
  try {
    const response = await api.post(
      '/billing/success',
      {
        sessionId,
      },
      {
        customHeaders: { token },
      }
    );

    const message = handleResponse(
      response,
      'Error Validating the Stripe Session Success'
    );

    return message;
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to login with Auth0');
  }
};
