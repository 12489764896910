import React from 'react';
import {
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
  useBreakpointValue,
  useColorModeValue,
  Tooltip,
  Text,
  Box,
} from '@chakra-ui/react';
import PurchaseCredits from '../PurchaseCredits/PurchaseCredits';
import { isOverCredits, hasLowCredits } from '../../config/config';

const CreditBanner = ({
  user,
  subscription,
  loading,
  accumulatedCredits,
  onOpenPurchaseModal,
  isModalOpen,
  onCloseModal,
}) => {

  const flexDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const width = useBreakpointValue({ base: '100%', md: '50%' });
  const padding = useBreakpointValue({ base: 4, md: 6 });

  const bgColor = useColorModeValue('beige.200', 'linear-gradient(to bottom, #3d4449, #22292f)');
  const cardBgColor = useColorModeValue('beige.300', 'gunmetal.600');
  const textColor = useColorModeValue('gray.800', 'white');
  const buttonBgColor = useColorModeValue('beige.300', 'beige.300');
  const buttonHoverBgColor = useColorModeValue('beige.400', 'beige.400');
  const modalBgColor = useColorModeValue('white', 'gunmetal.500');
  const subscriptionCreditsColor = useColorModeValue('beige.400', 'gunmetal.300');
  const purchasedCreditsColor = useColorModeValue('green.400', 'beige.300');

  const subscriptionCredits = user?.subscriptionCredits || 0;
  const purchasedCredits = user?.purchasedCredits || 0;
  const totalCredits = subscriptionCredits + purchasedCredits;
  const planCredits = subscription?.subscriptionPlan?.credits || 0;
  const progressPercentage = planCredits ? Math.min((totalCredits / planCredits) * 100, 100) : 0;
  const subscriptionPercentage = planCredits ? (subscriptionCredits / planCredits) * 100 : 0;
  const purchasedPercentage = planCredits ? (purchasedCredits / planCredits) * 100 : 0;

  return (
    <Flex flexDir={'column'} justify={'center'} align={'center'}>
      <Flex
        width={width}
        justify={'center'}
        align={'center'}
        flexDir={'column'}
        background={bgColor}
        shadow={'lg'}
        rounded={'2xl'}
        p={padding}
        mb={10}
      >
        <Flex flexDir={'column'}>
          {loading ? (
            <Flex align={'center'} justify={'center'}><Spinner color={textColor} /></Flex>
          ) : (
            <Heading as="h3" size="sm" fontWeight={'300'} textAlign="center" color={textColor}>
              Beta Trial - {subscription?.subscriptionPlan?.name}
            </Heading>
          )}

          <Divider my={4} borderColor={textColor} />

          <Text fontSize={['xs', 'sm', 'md']} color={textColor} textAlign="center" mb={4}>
            Your plan offers {planCredits} credits.
          </Text>

          {/* Progress Bar with Tooltips */}
          <Flex
            w={'100%'}
            bg={'gray.500'}
            rounded={'full'}
            overflow={'hidden'}
            h={[6, 8]}
            mb={4}
            position="relative"
          >
            <Tooltip
              label={`Purchased Credits: ${purchasedCredits}`}
              aria-label="Purchased Credits Info"
              hasArrow
              bg={cardBgColor}
              color={textColor}
              placement="top"
            >
              <Box
                bg={purchasedCreditsColor}
                w={`${purchasedPercentage}%`}
                h={'100%'}
                cursor="pointer"
              />
            </Tooltip>
            <Tooltip
              label={`Subscription Credits: ${subscriptionCredits}`}
              aria-label="Subscription Credits Info"
              hasArrow
              bg={cardBgColor}
              color={textColor}
              placement="top"
            >
              <Box
                bg={subscriptionCreditsColor}
                w={`${subscriptionPercentage}%`}
                h={'100%'}
                cursor="pointer"
              />
            </Tooltip>
            {isOverCredits && (
              <Tooltip
                label={`Additional Credits: ${totalCredits - planCredits}`}
                aria-label="Additional Credits Info"
                hasArrow
                bg={cardBgColor}
                color={textColor}
                placement="top"
              >
                <Box
                  bg={purchasedCreditsColor}
                  w={`${progressPercentage - 100}%`}
                  h={'100%'}
                  position="absolute"
                  right={0}
                  cursor="pointer"
                />
              </Tooltip>
            )}
          </Flex>

          {loading ? (
            <Flex align={'center'} justify={'center'}><Spinner color={textColor} /></Flex>
          ) : (
            <Heading
              as="h3"
              size={['xs', 'sm']}
              fontWeight={'300'}
              textAlign="center"
              color={isOverCredits ? 'green.400' : (hasLowCredits ? 'red.400' : textColor)}
            >
              {totalCredits} / {planCredits} credits remaining
            </Heading>
          )}

          <Button
            size={['sm', 'md']}
            background={buttonBgColor}
            p={[4, 6]}
            mt={4}
            onClick={onOpenPurchaseModal}
            _hover={{ bg: buttonHoverBgColor }}
          >
            Purchase more credits
          </Button>

          {/* Modal */}
          <Modal isOpen={isModalOpen} onClose={onCloseModal} size={'4xl'}>
            <ModalOverlay />
            <ModalContent background={modalBgColor} p={4}>
              <ModalHeader color={textColor}>Purchase Credits</ModalHeader>
              <ModalCloseButton color={textColor} />
              <ModalBody>
                <PurchaseCredits />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CreditBanner;
