import React from 'react';
import {
  Box,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const Help = () => {
  const scribeGuides = [
    {
      title: 'How to use Scriptweaver?',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      title: 'How to use Scriptweaver?',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  ];

  const pendulumGuides = [
    {
      title: 'How to use Pendulum?',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      title: 'How to use Pendulum?',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  ];

  const faqs = [
    {
      title: 'Frequently asked question 1',
      content: 'Answer for frequently asked question 1.',
    },
    {
      title: 'Frequently asked question 2',
      content: 'Answer for frequently asked question 2.',
    },
    {
      title: 'Frequently asked question 3',
      content: 'Answer for frequently asked question 3.',
    },
  ];

  return (
    <Box p={10}>
      <Heading mb={10} as={'h1'}>
        Help & Documentation
      </Heading>

      <Heading size="md" mb={3} as={'h2'}>
        Getting Started
      </Heading>
      <Text mb={10}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
        scelerisque, nisl quis tincidunt facilisis, sapien nunc dignissim
      </Text>

      <Heading size="md" mb={5} as={'h2'}>
        How-To Guides
      </Heading>
      <Heading size="sm" mb={2} as={'h3'}>
        ScriptWeaver
      </Heading>

      <Accordion allowToggle mb={10}>
        {scribeGuides.map((guide, index) => (
          <AccordionItem key={index}>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {guide.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>{guide.content}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <Heading size="sm" mb={2} as={'h3'}>
        Pendulum
      </Heading>
      <Accordion allowToggle mb={10}>
        {pendulumGuides.map((guide, index) => (
          <AccordionItem key={index}>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {guide.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>{guide.content}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <Heading size="md" mb={2} as={'h2'}>
        FAQs
      </Heading>
      <Accordion allowToggle mb={10}>
        {faqs.map((faq, index) => (
          <AccordionItem key={index}>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {faq.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>{faq.content}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      <Heading size="md" mb={3}>
        Troubleshooting
      </Heading>
      <Text mb={10}>
        Provide help for common issues that users may encounter while using the
        application.
      </Text>

      <Heading size="md" mb={3}>
        Contact Us
      </Heading>
      <Text>
        If you need further assistance, please contact us at&nbsp;
        <Link
          to="mailto:support@epicly.ai"
          style={{ color: 'beige.400', textDecoration: 'underline' }}
        >
          support@epicly.ai
        </Link>
        .
      </Text>
    </Box>
  );
};

export default Help;
