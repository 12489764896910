import React from 'react';
import { useUserContext } from '../../providers/UserProvider';
import { useMediaQuery } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { Flex, Container, Spacer } from '@chakra-ui/react';
import { pageTransition, pageVariants } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import CreditsInfo from '../CreditsInfo/CreditsInfo';
import UserInfo from '../UserInfo/UserInfo';
import UtilityButtons from '../UtilityButtons/UtilityButtons';

const FullScreenLayout = ({ children }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const [isSmallScreen] = useMediaQuery('(max-width: 767px)');
  const { subscription, user, loading, logout } = useUserContext();

  const goToHelp = () => {
    navigate('/help');
  };

  const goToSettings = () => {
    navigate('/settings');
  };

  return (
    <>
      <Flex direction="column">
        {isAuthenticated && (
          <Flex w="100%" p={4} align="center" gap={4} direction={{ base: 'column', md: 'row' }}>
            <CreditsInfo user={user} subscription={subscription} loading={loading} />
            <Spacer />
            <UserInfo user={user} loading={loading} />
            <UtilityButtons logout={logout} loading={loading} goToHelp={goToHelp} goToSettings={goToSettings} />
          </Flex>
        )}
        <Container maxW="100%" px={6} overflowX="hidden">
          <AnimatePresence mode="wait">
            <motion.div
              key={window.location.pathname}
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </Container>
      </Flex>
    </>
  );
};

export default FullScreenLayout;
