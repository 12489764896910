const styles = {
  global: (props) => ({
    body: {
      height: '100%',
      width: '100%',
      backgroundAttachment: 'fixed',
      margin: 0,
      fontFamily: 'effra-cc, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '1rem',
      color: props.colorMode === 'light' ? 'lightMode.text' : 'darkMode.text',
      bg: props.colorMode === 'light' ? 'lightMode.bg' : 'darkMode.bg',
      lineHeight: 'base',
    },
    a: {
      color: props.colorMode === 'light' ? 'lightMode.secondary' : 'darkMode.secondary',
      _hover: {
        textDecoration: 'none',
        color: 'beige.100',
      },
    },
  }),
};

export default styles;
