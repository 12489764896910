import { useState, useEffect, useCallback } from 'react';
import { getScriptById } from '../services/script.service';
import { useUserContext } from '../providers/UserProvider';

const useGetScriptById = (scriptId) => {
  const [script, setScript] = useState(null);
  const [scriptLoading, setScriptLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user, token } = useUserContext();

  const fetchScript = useCallback(async () => {
    setScriptLoading(true);
    setError(null);
    try {
      if (user && token && scriptId) {
        const fetchedScript = await getScriptById(scriptId, token);
        setScript(fetchedScript);
      }
    } catch (err) {
      console.error("Error fetching script:", err);
      setError(err);
    } finally {
      setScriptLoading(false);
    }
  }, [user, token, scriptId]);

  useEffect(() => {
    fetchScript();
  }, [fetchScript]);

  return {
    script,
    scriptLoading,
    error,
    refetch: fetchScript
  };
};

export default useGetScriptById;
