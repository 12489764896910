// src/services/user.service.js

import api from '../utils/api.utils';
import { handleResponse } from '../utils/app.utils';

// Handles Auth0 login by sending user data to the server.
export const handleAuth0Login = async (auth0User, token, referralCode = null) => {
  try {
    const userData = {
      email: auth0User.email,
      picture: auth0User.picture,
      referralCode
    };

    const response = await api.post('/users/auth0', userData, {
      customHeaders: { token },
    });

    const user = handleResponse(response, 'Error logging in with Auth0');

    return user;
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to login with Auth0');
  }
};

// Retrieves a user by their ID.
export const getUserById = async (userId, token) => {
  try {
    const response = await api.get(`/users/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return handleResponse(response, 'Error getting user by ID');
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to get user by ID');
  }
};

// Updates a user's data
export const updateUser = async (userId, userData, token) => {
  try {
    const response = await api.patch(`/users/${userId}`, userData, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error updating user');
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to update user');
  }
};

// Deletes a user from the server.
export const deleteUser = async (userId, token) => {
  try {
    const response = await api.delete(`/users/${userId}`, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error deleting user');
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to delete user');
  }
};

// Retrieves the subscription information for a user.
export const getUserSubscription = async (userId, token) => {
  try {
    const response = await api.get(`/users/${userId}/subscription`, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error fetching user subscription');
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to fetch user subscription');
  }
};

// Retrieves the active subscription for a user.
export const getActiveSubscription = async (userId, token) => {
  try {
    const response = await api.get(`/users/${userId}/subscriptions`, {
      customHeaders: { token },
    });
    return handleResponse(response, 'Error fetching active subscriptions');
  } catch (error) {
    console.error('Error', error.message);
    throw new Error('Failed to fetch active subscriptions');
  }
};