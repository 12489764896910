import React from 'react';
import { Box, Button, SimpleGrid, Text, Heading, useBreakpointValue, Switch, Flex, VStack, Badge, FormLabel } from '@chakra-ui/react';
import useGetPlans from '../../hooks/useGetPlans';

const SubscriptionPlan = ({ user, subscription }) => {
  const { plans, loading, isAnnual, getPlanPrice, toggleInterval } = useGetPlans();
  const columnCount = useBreakpointValue({ base: 1, md: 3 });
  const spacingValue = useBreakpointValue({ base: 4, md: 10 });

  const handleSubscriptionChange = (planId) => {
    console.log('planId:', planId);
  };

  const manageSubscriptionUrl = 'https://www.pizzapay.com/settings';

  if (loading) {
    return <Text>Loading plans...</Text>;
  }

  return (
    <>
      <Heading as="h2" size="md" mb={2}>
        Your current subscription:
      </Heading>
      <Badge mb={10} colorScheme='green' fontSize='lg' p={2}>
        {subscription.subscriptionPlan.name
          ? subscription.subscriptionPlan.name
          : 'None'}
      </Badge>

      <Flex align="center" justify="center" mb={6}>
        <FormLabel>
          <Text mr={8} textAlign={'right'}>Monthly</Text>
          </FormLabel>
          <Switch
            size="lg"
            variant='custom'
            isChecked={isAnnual}
            onChange={toggleInterval}
          />
          <FormLabel>
            <Text ml={2} textAlign={'left'}>Annual</Text>
          </FormLabel>
      </Flex>

      <SimpleGrid columns={columnCount} spacing={spacingValue} mb={10}>
        {plans.map(plan => (
          <Box
            key={plan._id}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            p={{ base: 4, md: 6 }}
            display="flex"
            flexDirection="column"
            height="100%"
          >
            <VStack spacing={4} align="stretch" flex="1">
              <Text
                color="gray.500"
                fontWeight="semibold"
                letterSpacing="wide"
                fontSize="xs"
                textTransform="uppercase"
              >
                {plan.name} • ${getPlanPrice(plan)}/{isAnnual ? 'year' : 'month'}
              </Text>
              <Text flex="1">{plan.description}</Text>
              { plan.name === subscription.subscriptionPlan.name ? (
                <Text>Current Plan</Text>
              ) : (
                <Text>Credits: {plan.credits}</Text>
              )}
            </VStack>
            <Button
              isDisabled={subscription?.subscriptionPlan._id === plan._id}
              onClick={() => handleSubscriptionChange(plan._id)}
              w="full"
              mt={4}
            >
              Select
            </Button>
          </Box>
        ))}
      </SimpleGrid>

      <Button as="a" href={manageSubscriptionUrl} target="_blank">
        Manage Subscription
      </Button>
    </>
  );
};

export default SubscriptionPlan;
